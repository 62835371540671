import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Typography, CircularProgress } from '@mui/material';
import { loginUser } from '../api';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

interface LoginPageProps {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DecodedToken {
  exp: number;
  // include other properties that you expect to receive from jwtDecode
}

const LoginPage: React.FC<LoginPageProps> = ({ isLoggedIn, setIsLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && !isInitialLoad) {
      navigate('/');
    } else {
      setIsInitialLoad(false); // Set it to false after the initial check
    }
  }, [isLoggedIn, navigate, isInitialLoad]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {
        setIsLoggedIn(true);

      } else {
        // Token is expired
        setIsLoggedIn(false);
        localStorage.removeItem('token');
      }
      
    }
  }, [setIsLoggedIn]);

  const handleLogin = async (e:any) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const success = await loginUser(username, password);
      console.log(success);
      if (!success) {
        setError('Login failed');
      } else {
        setIsLoggedIn(true);
      }
      navigate('/');
    } catch (err) {
      setError('An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="background.default"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleLogin}>
        <Box mb={2}>
          <TextField 
            label="Username" 
            variant="outlined" 
            fullWidth 
            value={username} 
            onChange={(e) => setUsername(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField 
            label="Password" 
            type="password" 
            variant="outlined" 
            fullWidth 
            value={password} 
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="secondary" /> : 'Login'}
        </Button>
      </form>
      {error && <p>{error}</p>}
    </Box>
  );
};

export default LoginPage;
