import React, {useState, useEffect} from 'react';
import { Button, TextField, Box, Typography, Select, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import {getUserById, updateUser } from '../api';

interface DecodedToken {
    id: string;
    activeSubscription: boolean;
    exp: number;
    role: string;
    // Include any other properties you expect to be in the token
  }

const SettingsPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
  });

  useEffect(() => {
    populateForm();
}, []);

  const populateForm = async () => {
    const token = localStorage.getItem('token');
    
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
      setUserId(decodedToken.id);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {
        const userData = await getUserById(decodedToken.id);
        setFormData({
          email: userData.email,
          password: '',
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          role: userData.role,
        })
      } else {
        // Token is expired
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
  }}

  const handleChange = (e:any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async (e:any) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
        await updateUser(formData, userId);
        alert('Userdata updated');
    } catch (error) {
        alert('Something went wrong. Please try again');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <Box
      display="flex" 
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="background.default"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Settings
      </Typography>
      <form onSubmit={handleSave}>
        <Box mb={2}>
            <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
            />
            </Box>
            <Box mb={2}>
            <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
            />
            </Box>
            <Box mb={2}>
            <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                name="phone"
                value={formData.phone}
                onChange={handleChange}
            />
        </Box>
        <Box mb={2}>
        <TextField
            label="Email"
            variant="outlined"
            fullWidth
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
        <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </Box>
        
        <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="secondary" /> : 'Save'}
        </Button>
      </form>
    </Box>
  );
};

export default SettingsPage;
