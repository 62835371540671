import React, { useState, useEffect, useRef  } from 'react';
import mongoose, { Schema, Document } from 'mongoose';
import {
  format,
  startOfWeek,
  eachDayOfInterval,
  addWeeks,
  subWeeks,
  getISOWeek,
  addDays,
  endOfWeek,
} from 'date-fns';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { BiMessageSquareDetail } from 'react-icons/bi';
import SendIcon from '@mui/icons-material/Send';
import { FaSadTear, FaFrown, FaSmile, FaLaughBeam, FaMeh } from 'react-icons/fa';
import jwt_decode from 'jwt-decode';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartData, ChartDataset } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import PanToolIcon from '@mui/icons-material/PanTool';
import {
  Button,
  Typography,
  IconButton,
  Box,
  Modal,
  Grid,
  TextField, 
  Container, 
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HelpIcon from '@mui/icons-material/Help';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import StatsWeeklyAdmindashComp from './StatsWeeklyAdmindashComp';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { 
  createTrainingSession, deleteTrainingSessionById, updateTrainingSessionById, getUserTrainingSessionsLastTenWeeks, saveWeeklyComment,
  getWeeklyComments, updateWeeklyComments, saveWeeklyCoachComment, updateWeeklyCoachComments,  getDailyMarkersByUserIdAndDate, createDailyMarkerAPI, updateDailyMarkerAPI,getUserTrainingSessionsLastTenWeeksByUserId
    } from '../api';
import { TrainingSessionData, TrainingSessions } from '../models/trainingSessionsModelFrontend';
import { User } from '../models/userModelFronted';
import {WeeklyComments} from '../models/weeklyCommentsModelFrontend';
import { DailyMarker, NewDailyMarker } from './../models/dailyMarkersModelFronted';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';

type PartialTrainingSessions = {
  [K in keyof TrainingSessions]?: TrainingSessions[K];
};

interface LoadByDate {
  [date: string]: number;
}

interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  // Include any other properties you expect to be in the token
}

interface UserDashOnAdminProps {
    userId: string | null;
  }

  const UserDashOnAdmin: React.FC<UserDashOnAdminProps> = ({ userId }) => {
  const [selectedWeeks, setSelectedWeeks] = useState([new Date(), ...Array(4).fill(null).map((_, i) => subWeeks(new Date(), i + 1))]);
  const [trainingSessions, setTrainingSessions] = useState<TrainingSessions[]>([]);
  const [open, setOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState<TrainingSessions | null>(null);
  const [updatedSession, setUpdatedSession] = useState<PartialTrainingSessions | null>(null);
  const [newSession, setNewSession] = useState<Partial<TrainingSessions>>({date: new Date()});
  const [loading, setLoading] = useState(false);
  const weekStart = startOfWeek(selectedWeeks[0], { weekStartsOn: 1 });
  const [weeklyComment, setWeeklyComment] = useState('');
  const [weeklyCoachComment, setWeeklyCoachComment] = useState('');
  const [dailyMarkers, setDailyMarkers] = useState<DailyMarker[]>([]);
  const [weeklyUserComments, setWeeklyUserComments] = useState<{ [key: string]: string }>({});
  const [weeklyCoachComments, setWeeklyCoachComments] = useState<{ [key: string]: string }>({});
  const [ewmaChartData, setEwmaChartData] = useState<ChartData<'line', number[], string>>({
    labels: [],
    datasets: [
      {
        label: 'EWMA ACWR',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        type: 'line' // Explicitly set the type here
      },
    ],
  });

  const onDragEnd = (result: any) => {
    const { source, destination } = result;
  
    if (!destination) {
      return;
    }
  
    if (source.droppableId !== destination.droppableId || source.index !== destination.index) {
      const sessionId = result.draggableId.replace('session-', '');
      const sessionData = trainingSessions.find(session => session._id === sessionId);
  
      if (sessionData) {
        const weekIndex = 4 - parseInt(destination.droppableId.split('-')[1], 10); // Reversed weekIndex calculation
        const dayIndex = parseInt(destination.droppableId.split('-')[2], 10);
        const destinationWeekStart = startOfWeek(selectedWeeks[weekIndex], { weekStartsOn: 1 });
        const newDate = addDays(destinationWeekStart, dayIndex);
  
        handleMoveSession(newDate, sessionId, sessionData);
      }
    }
  };
  
  const prepareTrainingSessions = (sessions: TrainingSessions[]) => {
    const today = new Date();
    today.setHours(23, 59, 59, 999); // Set to end of today
  
    const validSessions = sessions.filter(session => {
      const sessionDate = new Date(session.date);
      return sessionDate <= today ? session.executed : true;
    });
  
    return validSessions;
  };

  function calculateDailyTrainingLoad(sessions: TrainingSessions[]): LoadByDate {
    const loadByDate: LoadByDate = {};
  
    // Find the earliest session date
    let earliestDate = new Date();
    sessions.forEach(session => {
      const sessionDate = new Date(session.date);
      if (sessionDate < earliestDate) {
        earliestDate = sessionDate;
      }
    });
  
    // Initialize load for each day from the earliest date to today
    for (let d = new Date(earliestDate); d <= new Date(); d.setDate(d.getDate() + 1)) {
      const dateKey = format(d, 'yyyy-MM-dd');
      loadByDate[dateKey] = 0; // Initialize with 0 load
    }
  
    // Calculate load for each session
    sessions.forEach(session => {
      const sessionDateKey = format(new Date(session.date), 'yyyy-MM-dd');
      const load = session.intensityRatingOfPerceivedExertion * session.durationInMinutes;
      loadByDate[sessionDateKey] += load;
    });
  
    return loadByDate;
  }

  function calculateEWMA(loadByDate: LoadByDate, acutePeriod: number, chronicPeriod: number) {
    const ewmaAcute: LoadByDate = {};
    const ewmaChronic: LoadByDate = {};
  
    const sortedDates = Object.keys(loadByDate).sort();
  
    const alphaAcute = 2 / (acutePeriod - 1);
    const alphaChronic = 2 / (chronicPeriod - 1);
  
    sortedDates.forEach((date, index) => {
      if (index === 0) {
        ewmaAcute[date] = loadByDate[date] * (alphaAcute) + ((1 - (alphaAcute)) * 1);
        ewmaChronic[date] = loadByDate[date] * (alphaChronic) + ((1 - (alphaChronic)) * 1);
      } else {
        const previousDate = sortedDates[index - 1];
        ewmaAcute[date] = loadByDate[date] * (alphaAcute) + ((1 - (alphaAcute)) * ewmaAcute[previousDate]);
        ewmaChronic[date] = loadByDate[date] * (alphaChronic) + ((1 - (alphaChronic)) * ewmaChronic[previousDate]);
      }
    });
  
    return { ewmaAcute, ewmaChronic };
  }
  

  function calculateACWR(ewmaAcute: LoadByDate, ewmaChronic: LoadByDate) {
    const acwr: LoadByDate = {};
    Object.keys(ewmaAcute).forEach(date => {
      acwr[date] = ewmaAcute[date] / ewmaChronic[date];
    });
    return acwr;
  }

  const handleWeeklyCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, week: Date) => {
    const weekNumber = getISOWeek(week);
    setWeeklyCoachComments({
      ...weeklyCoachComments,
      [weekNumber]: event.target.value,
    });
  };
  
  //TODO: CHANGE SO THAT THE COACH COMMENT IS UPDATED OR CREATED
  const handleSaveWeeklyCoachComment = async () => {
    try {
      let fetchUserId = userId;
      if (!fetchUserId) {
        const token = localStorage.getItem('token');
        if (token === null) {
          console.error("Token not found in local storage");
          return; // End the function if there's no token
        }
        const decodedToken = jwt_decode(token) as DecodedToken;
        fetchUserId = decodedToken.id;
      }
  
      for (const [weekNumber, coachComment] of Object.entries(weeklyCoachComments)) {
        const existingComments = await getWeeklyComments(fetchUserId, Number(weekNumber));

  
        if (existingComments.length > 0) {
          // If a comment exists, update it
          const commentId = existingComments[0]._id;
          await updateWeeklyCoachComments(commentId, coachComment as string);
        } else {
          // If no comment exists, save a new one
          await saveWeeklyCoachComment(fetchUserId, Number(weekNumber), coachComment as string);
        }
      }

    } catch (error) {
      console.error('Failed to save or update comment:', error);
    }
  };
  
  const weekDays = eachDayOfInterval({
    start: weekStart,
    end: addDays(addWeeks(weekStart, 1), -1),
  });

  function getWeekRange(dateInput: Date) {
    const date = new Date(dateInput.valueOf()); // create a copy of the date
    date.setHours(0, 0, 0, 0);
  
    const weekStart = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1); // adjust when day is Sunday
    const weekEnd = weekStart + 6; // end day is the first day + 6
  
    const start = new Date(date.setDate(weekStart));
    const end = new Date(date.setDate(weekEnd));
  
    return { start, end };
  }

  const theme = useTheme();

  const getActivityIcon = (activity: any) => {
    switch (activity) {
      case 'Running':
      case 'running':
        return <DirectionsRunIcon />;
      case 'Cycling':
      case 'cycling':
        return <DirectionsBikeIcon />;
      case 'swimming':
      case 'Swimming':
        return <PoolIcon />;
      case 'strength':
      case 'Strength':
      case 'StrengthTraining':
        return <FitnessCenterIcon />;
      default:
        return <HelpIcon />;
    }
  };

  useEffect(() => {
    const fetchTrainingSessions = async () => {
      try {
        const fetchUserId = userId || '';
        const fetchedTrainingSessions = await getUserTrainingSessionsLastTenWeeksByUserId(fetchUserId);
  
        if (Array.isArray(fetchedTrainingSessions)) {
          setTrainingSessions(fetchedTrainingSessions);  // Store the fetched training sessions

        } else {
          console.error('Fetched data is not an array:', fetchedTrainingSessions);
        }
      } catch (error) {
        console.error('Error fetching the training sessions:', error);
      }
    }; 
  
    fetchTrainingSessions();
  }, [selectedWeeks,userId]);


  useEffect(() => {
    const fetchWeeklyComments = async () => {
      try {
        let fetchUserId = userId;
        if (!fetchUserId) {
          const token = localStorage.getItem('token');
          if (token === null) {
            console.error("Token not found in local storage");
            return; // End the function if there's no token
          }
          const decodedToken = jwt_decode(token) as DecodedToken;
          fetchUserId = decodedToken.id;
        }
        
        const comments = await getWeeklyComments(fetchUserId, getISOWeek(selectedWeeks[0]));
        setWeeklyComment(comments[0]?.userComment || 'No comment');
        setWeeklyCoachComment(comments[0]?.coachComment || '');
  
        const { end } = getWeekRange(selectedWeeks[0]);
        const { start } = getWeekRange(selectedWeeks[selectedWeeks.length - 1]);
  
        const dailyMarkers = await getDailyMarkersByUserIdAndDate(fetchUserId, start.toISOString(), end.toISOString());
        setDailyMarkers(dailyMarkers);

        let userComments: { [key: string]: string } = {};
        let coachComments: { [key: string]: string } = {};
      
        for (const week of selectedWeeks) {
          const weekNumber = getISOWeek(week).toString(); // Convert to string
      
          const comments = await getWeeklyComments(fetchUserId, Number(weekNumber)); // Convert back to number for API call
      
          if (comments.length > 0) {
            userComments[weekNumber] = comments[0].userComment;
            coachComments[weekNumber] = comments[0].coachComment;
          } else {
            userComments[weekNumber] = '';
            coachComments[weekNumber] = '';
          }
        }
      
        setWeeklyUserComments(userComments);
        setWeeklyCoachComments(coachComments);
  
      } catch (error) {
        console.error('Failed to fetch weekly comments:', error);
      }
    };
  
    fetchWeeklyComments();
  }, [selectedWeeks, userId]);

  const changeWeek = (direction: string) => {
    setSelectedWeeks(selectedWeeks.map(week => direction === 'prev' ? subWeeks(week, 1) : addWeeks(week, 1)));
  };


  useEffect(() => {
    if (trainingSessions.length > 0) {
      const validSessions = prepareTrainingSessions(trainingSessions);
      const loadByDate = calculateDailyTrainingLoad(validSessions);
      const { ewmaAcute, ewmaChronic } = calculateEWMA(loadByDate, 7, 28); // Example parameters for acute and chronic periods, and alpha
      const acwr = calculateACWR(ewmaAcute, ewmaChronic);
  
      // Determine the start and end dates for the chart based on selectedWeeks
      const startDate = startOfWeek(selectedWeeks[selectedWeeks.length - 1], { weekStartsOn: 1 });
      const endDate = endOfWeek(selectedWeeks[0], { weekStartsOn: 1 });
  
      // Filter and prepare chart data for the selected weeks
      const chartLabels = Object.keys(acwr)
        .sort()
        .filter(date => new Date(date) >= startDate && new Date(date) <= endDate);
      const chartData = chartLabels.map(date => acwr[date]);
  
      setEwmaChartData({
        labels: chartLabels,
        datasets: [
          {
            label: 'EWMA ACWR',
            data: chartData,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }
        ]
      });
    }
  }, [trainingSessions, selectedWeeks]);
  
  
  const renderWeeks = () => {
    const weeks = [];
    for (let i = 0; i < 3; i++) {
      const week = startOfWeek(subWeeks(new Date(), i), { weekStartsOn: 1 });
      weeks.push(
        <Button
          key={i}
          variant={
            getISOWeek(selectedWeeks[0]) === getISOWeek(week)
              ? 'contained'
              : 'outlined'
          }
          sx={{
            backgroundColor:
              getISOWeek(selectedWeeks[0]) === getISOWeek(week)
                ? 'primary.main'
                : 'default',
            color: getISOWeek(selectedWeeks[0]) === getISOWeek(week)
              ? 'primary.contrastText'
              : 'default',
          }}
          onClick={() => {
            // Calculate new set of weeks starting from the clicked week
            const newWeeks = [week, ...Array(4).fill(null).map((_, idx) => subWeeks(week, idx + 1))];
            setSelectedWeeks(newWeeks);
          }}
        >
          {format(week, 'w')}
        </Button>,
      );
    }
    return weeks.reverse();
  };
            
// Add a function to handle the running icon click
const handleRunningIconClick = (session: TrainingSessions) => {
  setSelectedSession(session);
  setUpdatedSession(session);
  setOpen(true);
};

// Add a function to close the modal
const handleClose = () => {
setOpen(false);
};

function getNextMonday(): string {
  let date = new Date();
  const day = date.getDay();
  const difference = (day === 0) ? 1 : 8 - day;

  date.setDate(date.getDate() + difference);

  // Format the date to YYYY-MM-DD
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const dayOfMonth = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${dayOfMonth}`;
  }

function getWeekNumber(date: Date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}         

const handleChange = (field: string, value: any) => {
  if (selectedSession) {
    setUpdatedSession({
      ...updatedSession ?? {},
      [field]: value,
    });
  }
};

const handleFeelingClick = (feeling: string) => {
  handleChange('feelingDuring', feeling);
};

const handleMoveSession = async (day: Date, sessionId: string, sessionData: TrainingSessions) => {
  // Adjust the date to include the local timezone offset
  const timezoneOffset = day.getTimezoneOffset() * 60000; // Convert offset to milliseconds
  const adjustedDate = new Date(day.getTime() - timezoneOffset);

  const updatedSessionData = {
    ...sessionData,
    date: adjustedDate.toISOString()
  };

  try {
    const updatedSession = await updateTrainingSessionById(sessionId, updatedSessionData);
    console.log('Session updated:', updatedSession);

    // Update the state to trigger re-render
    setTrainingSessions(prevSessions => prevSessions.map(session => 
      session._id === sessionId ? { ...session, date: adjustedDate } : session
    ));
  } catch (error) {
    console.error('Error updating session:', error);
  }
};





const handleUpdate = async () => {
  if (selectedSession && updatedSession && selectedSession._id) {
    setLoading(true);
    const updatedSessionWithExecuted = { ...updatedSession, executed: false };
    try {
      const updated = await updateTrainingSessionById(selectedSession._id.toString(), updatedSessionWithExecuted);
      if (updated) {
        // Find the index of the updated session in the trainingSessions array
        const updatedSessionIndex = trainingSessions.findIndex(
          (session) => session._id === updated._id
        );

        // Replace the session at the found index with the updated session
        if (updatedSessionIndex > -1) {
          const newTrainingSessions = [...trainingSessions];
          newTrainingSessions[updatedSessionIndex] = updated;
          setTrainingSessions(newTrainingSessions);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  }
};

const handleDelete = async () => {
  if (selectedSession && selectedSession._id) {
    setLoading(true);
    try {
      const deleted = await deleteTrainingSessionById(selectedSession._id.toString() ?? '');
      if (deleted) {
        // Remove the deleted session from the trainingSessions array
        const newTrainingSessions = trainingSessions.filter(
          (session) => session._id !== selectedSession._id
        );
        setTrainingSessions(newTrainingSessions);

        // Close the modal
        
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  }
};

const getColor = (intensity: any) => {
  const r = Math.round(255 * intensity / 10);
  const g = Math.round(255 - (255 * intensity / 10));
  return `rgb(${r}, ${g}, 0)`;
};

const modalContainerStyles = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    paddingTop: '56px', // for mobile address bar
  },
};

const modalStyles = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '100%',
  maxWidth: '80%',
  minWidth: '30%',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'calc(100% - 56px)', // subtract address bar height
    borderRadius: 0,
  },
};

const getMoodIcon = (mood: any) => {
  switch (mood) {
    case 'lousy':
      return <FaSadTear style={{ color: 'lightblue', fontSize: 50 }} />;
    case 'poor':
      return <FaFrown style={{ color: 'lightblue', fontSize: 50 }} />;
    case 'good':
      return <FaSmile style={{ color: 'lightblue', fontSize: 50 }} />;
    case 'great':
      return <FaLaughBeam style={{ color: 'lightblue', fontSize: 50 }} />;
    default:
      return <FaMeh style={{ color: 'lightblue', fontSize: 50 }} />;  // default icon for undefined/empty mood
  }
};

const getTooltipContent = (marker: DailyMarker | undefined) => {
  if (!marker) return '';

  let content = '';
  if (marker.recoveryStatus) content += `${marker.recoveryStatus}\n`;
  if (marker.restedStatus) content += `${marker.restedStatus}\n`;
  if (marker.motivationStatus) content += `${marker.motivationStatus}\n`;
  if (marker.stressStatus) content += `${marker.stressStatus}\n`;
  if (marker.sleepQualityStatus) content += `${marker.sleepQualityStatus}\n`;
  if (marker.sleepQuantityStatus) content += `${marker.sleepQuantityStatus}\n`;
  if (marker.injuryStatus) content += `Injury status: ${marker.injuryStatus}\n`;

  return content;
}

const handleAddIconClick = () => {
  setOpenAddModal(true);
};

const handleAddClose = () => {
  setOpenAddModal(false);
  setNewSession({
    date: new Date(),
    activity: '',
    sessionTitle: '',
    sessionDescription: '',
  });
};

const handleCreateSession = async () => {
  setLoading(true);
  try {
    const token = localStorage.getItem('token');
      
      if (token === null) {
        console.error("Token not found in local storage");
        return; // End the function if there's no token
      }

      const decodedToken = jwt_decode(token) as DecodedToken;
      const currentUserId = decodedToken.id;
    // Set userId and executed values
    const sessionToCreate = {
      ...newSession,
      userId: userId || currentUserId,
      executed: false,
    };

    // Call the API to create the new training session
    const createdSession = await createTrainingSession(sessionToCreate);

    if (createdSession) {
      // Update the local state to include the newly created session
      setTrainingSessions((prevSessions) => [...prevSessions, createdSession]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
    handleAddClose();
    // Reset the newSession state
    
  }
};

const getTooltipForSession = (session:any) => {
  return `Description: ${session.sessionDescription || 'N/A'}
    \nComment: ${session.sessionComment || 'N/A'}`;
};

const getTitleVisual = (title:any) => {
  return (
    <Typography variant="h6" display="block">
      {title ? `${title}` : ' '}
      {title && <span>&nbsp;&nbsp;</span>}
    </Typography>
  );
};

const getDurationVisual = (duration: any) => {
  return (
    <Typography variant="button" display="block">
      {duration ? `${duration} min` : ''}
      {duration && <span>&nbsp;</span>}
    </Typography>
  );
};

const getFeelingVisual = (feeling: any) => {
  return (
    <Typography variant="button" display="block">
      {feeling ? `- Feel: ${feeling}` : ''}
      {feeling && <span>&nbsp;</span>}
    </Typography>
  );
};

const getIntensityVisual = (intensity:any) => {
  return (
    <Typography variant="button" display="block">
      {intensity ? `- RPE: ${intensity}` : ''}
      {intensity && <span>&nbsp;</span>}
    </Typography>
  );
};  
           
return (
  <DragDropContext onDragEnd={onDragEnd}>
  <Container maxWidth={false}>
    
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Button onClick={() => changeWeek('prev')}>Prev</Button>
      {renderWeeks()}
      <Button onClick={() => changeWeek('next')}>Next</Button>
      <IconButton 
          onClick={handleAddIconClick} 
          sx={{ 
            mx: 1,
            border: '1px solid #616469', // blue border
            '&:hover': {
              backgroundColor: '#1976d2', // blue hover effect
              color: 'white',
            },
          }}
        >
          <AddIcon />
        </IconButton>
      <Typography variant="h5" sx={{ flexGrow: 1, textAlign: 'start' }}>
        Add spesific session
      </Typography>
    </Box>
    
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', pt: 2, height:"100%" }}>
        {[...selectedWeeks].reverse().map((week, weekIndex) => {
        const weekDays = eachDayOfInterval({
          start: startOfWeek(week, { weekStartsOn: 1 }),
          end: endOfWeek(week, { weekStartsOn: 1 }),
        });
        
        return (

          <Box key={weekIndex} sx={{ 
            width: '100%',
            height: '100%', 
            borderRadius: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            backgroundColor: 'white',
            boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)', // subtle shadow for depth
            marginRight: '10px',
           }}>
            {weekDays.map((day, dayIndex) => {
              const dailyMarkerForThisDay = dailyMarkers.find(marker => {const markerDate = new Date(marker.timestamp);
                return format(day, 'yyyy-MM-dd') === format(markerDate, 'yyyy-MM-dd');
              });
              const tooltipContent = getTooltipContent(dailyMarkerForThisDay);

              return (
                <Droppable key={dayIndex} droppableId={`day-${weekIndex}-${dayIndex}`}>
                {(provided:any) => (
                  <Box ref={provided.innerRef}
                  {...provided.droppableProps} 
                    mb={2}
                    sx={{
                      alignItems: 'center',
                      width: '90%',
                      borderRadius: '5px',
                      p: 2,
                      backgroundColor: 'white',
                      boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)', // subtle shadow for depth
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6">
                        {format(day, 'EEEE, do MMMM')}
                      </Typography>
                      {dailyMarkerForThisDay?.mood && (
                        <Tooltip title={tooltipContent} arrow>
                          <div>{getMoodIcon(dailyMarkerForThisDay?.mood)}</div>
                        </Tooltip>
                      )}
                    </div>
                    {trainingSessions.filter((session) => format(day, 'yyyy-MM-dd') === format(new Date(session.date), 'yyyy-MM-dd'))
                      .map((session, sessionIndex) => (
                        <Draggable key={String(session._id)} draggableId={`session-${String(session._id)}`} index={sessionIndex}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              marginBottom: 1,
                              border: '1px solid #616469',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: session.executed ? 'lightgreen' : 'initial',
                              borderRadius: '5px',

                              '&:hover': {
                                backgroundColor: '#1976d2',
                                color: 'white',
                              },
                            }}>
                              <IconButton size="large">
                                <PanToolIcon />
                              </IconButton>
                            <Tooltip title={getTooltipForSession(session)} arrow>
                              <IconButton
                                key={sessionIndex}
                                onClick={() => handleRunningIconClick(session)}
                                sx={{
                                  justifyContent: 'space-between',
                                  mx: 1,
                                  borderRadius: '5px',
                                  
                                  '&:hover': {
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                  },
                                }}>
                                
                                <Box >
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-evenly' }}> 
                                  {getActivityIcon(session.activity)}
                                  {getTitleVisual(session.sessionTitle)}  
                                  </Box>
                                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>                                
                                  {getDurationVisual(session.durationInMinutes)}
                                  {getIntensityVisual(session.intensityRatingOfPerceivedExertion)}
                                  {getFeelingVisual(session.feelingDuring)}
                                </Box>
                                </Box>
                              </IconButton>
                            </Tooltip>
                            
                          </Box>
                        )}
                      </Draggable>
                      ))}
                    {provided.placeholder}
                  </Box>
                  )}
                </Droppable>
              );
            })}
            <Box mb={2}>
              <Typography variant="h5" align="center" gutterBottom>
                Athlete's comment
              </Typography>
              <Typography variant="body2" align="left" gutterBottom>
                {weeklyUserComments[getISOWeek(week)] || 'No comment'}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="h5" align="center" gutterBottom>
                Coach's comment
              </Typography>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                placeholder="Write your comment here..."
                value={weeklyCoachComments[getISOWeek(week)] || ''}
                onChange={(event) => handleWeeklyCommentChange(event, week)}
              />
              <Button variant="contained" color="primary" fullWidth style={{ marginTop: '10px' }} onClick={handleSaveWeeklyCoachComment}>
                Save
              </Button>
            </Box>
            <Box mb={2}>
                <Typography variant="h5" align="center" gutterBottom>
                    Statistics
                </Typography>
                <StatsWeeklyAdmindashComp 
                    dailyMarkers={dailyMarkers} 
                    trainingSessions={trainingSessions} 
                    week={week} // Pass only the current week
                  />
            </Box>
          </Box>
        );
      })}
    </Box>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="running-session-details"
  aria-describedby="running-session-details-details-description"
>
  <Box
    sx={modalContainerStyles}
  >
    <Box sx={modalStyles}>
    <IconButton
      edge="end"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        color: (theme:any) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    <Typography id="running-session-details" variant="h6" m={2}>
      Title
    </Typography>
    <TextField 
      id="running-session-details" 
      variant="outlined"
      fullWidth
      value={updatedSession ? updatedSession.sessionTitle : ''}
      onChange={(e) => handleChange('sessionTitle', String(e.target.value))}>
    </TextField>
    <Typography id="running-session-details-description" variant="h6" m={2}>
      Description
    </Typography>
    <TextField 
      id="running-session-details-description" 
      variant="outlined"
      fullWidth
      value={updatedSession?.sessionDescription??''}
      onChange={(e) => handleChange('sessionDescription', String(e.target.value))}>
    </TextField>

    {selectedSession && selectedSession.strengthTraining?.map((exercise, index) => (
        <Box key={index} mt={2}>
          <Typography variant="body1">
            {`${exercise.exercise}:`}
          </Typography>
          <Typography variant="body2">
            {`${exercise.sets} sets of ${exercise.repetitions} reps`}
          </Typography>
          
            </Box>
          ))}
    {selectedSession && (
      <>
        <ButtonGroup orientation="vertical"  style={{ backgroundColor: "primary", margin: '5px' }}
          aria-label="outlined primary button group">
          {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
            <Button
              key={num}
              onClick={() => handleChange('intensityRatingOfPerceivedExertion', num)}
              style={{
                backgroundColor:
                  updatedSession?.intensityRatingOfPerceivedExertion === num ? getColor(num) : '',
                  color:
                newSession.intensityRatingOfPerceivedExertion === num ? "#1a237e" : "#1a237e"
              }}
              >
                {num === 1 ? '1: Very easy' : num === 10 ? '10: Maximal effort' : num}
              </Button>
            ))}
          </ButtonGroup>
          

        <TextField
          label="Duration in Minutes"
          type="number"
          value={updatedSession?.durationInMinutes??''}
          onChange={(e) => handleChange('durationInMinutes', Number(e.target.value))}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Distance in KM"
          type="number"
          value={updatedSession?.distanceInKm??''}
          onChange={(e) => handleChange('distanceInKm', Number(e.target.value))}
          fullWidth
          margin="normal"
        />
        <Typography id="running-session-details-description" variant="body1">
            Comment from athlete:
        </Typography>
        <TextField
            id="running-session-details-description"
            multiline
            minRows={2}
            value={selectedSession ? selectedSession.comment : ''}
            disabled
            />

        <Typography variant="body1" mt={2}>
              Feeling During:
        </Typography>
        <ButtonGroup 
            color="primary" 
            aria-label="outlined primary button group"
            style={{ marginTop: '20px' }}  // Adjust this value as needed
            >
            {['lousy', 'poor', 'good', 'great'].map((feeling) => (
                <Button
                key={feeling}
                onClick={() => handleFeelingClick(feeling)}
                style={{
                    backgroundColor: updatedSession?.feelingDuring === feeling ? '#4fc3f7' : '',
                }}
                >
                {feeling}
                </Button>
            ))}
            </ButtonGroup>
            {loading && (
              <Box mt={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleUpdate}
              >
                Save session
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                sx={{ ml: 1 }}
              >
                Delete session
              </Button>
            </Box>
          </>
        )}
        </Box>
      </Box>
</Modal>
<Modal
    open={openAddModal}
    onClose={handleAddClose}
    aria-labelledby="add-session-details"
    aria-describedby="add-session-details-description"
  >
    <Box
    sx={modalContainerStyles}
  >
    <Box sx={modalStyles}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleAddClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: (theme:any) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography id="add-session-details" variant="h6" mb={2}>
        Add New Training Session
      </Typography>

      {/* Add input fields for new training session properties */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            format="dd-MM-yyyy"
            value={newSession.date}
            onChange={(newValue: Date | null) => {
              if (newValue !== null) {
                setNewSession({ ...newSession, date: newValue });
              }
            }}
            
            
          />
        </LocalizationProvider>
        <TextField
            label="Activity"
            select
            value={newSession.activity}
            onChange={(e) => setNewSession({ ...newSession, activity: e.target.value })}
            fullWidth
            margin="normal"
          >
            {/* Add options for activity here */}
            <MenuItem value="Running">Running</MenuItem>
            <MenuItem value="Cycling">Cycling</MenuItem>
            <MenuItem value="Swimming">Swimming</MenuItem>
            <MenuItem value="Strength">Strength</MenuItem>
            <MenuItem value="Circuit-training">Circuit training</MenuItem>
            <MenuItem value="Yoga">Yoga</MenuItem>
            <MenuItem value="Crossfit">Crossfit</MenuItem>
            <MenuItem value="Cross-country-skiing">Cross-country skiing</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
            {/* Add more activities as needed */}
          </TextField>
      <TextField
        label="Session Title"
        value={newSession.sessionTitle}
        onChange={(e) => setNewSession({ ...newSession, sessionTitle: e.target.value })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Session Description"
        value={newSession.sessionDescription}
        onChange={(e) => setNewSession({ ...newSession, sessionDescription: e.target.value })}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
            label="Session comment"
            value={newSession.comment}
            onChange={(e) => setNewSession({ ...newSession, comment: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
        />
      <Typography variant="body1" mt={2}>
        Intensity Rating of Perceived Exertion:
      </Typography>
      <ButtonGroup orientation="vertical" style={{ backgroundColor: "primary" }}
          aria-label="outlined primary button group">
        {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
          <Button
            key={num}
            onClick={() => setNewSession({ ...newSession, intensityRatingOfPerceivedExertion: num })}
            style={{
              backgroundColor:
                newSession.intensityRatingOfPerceivedExertion === num ? getColor(num) : '',
              color:
                newSession.intensityRatingOfPerceivedExertion === num ? "#1a237e" : "#1a237e"
              }}
              >
                {num === 1 ? '1: Very easy' : num === 10 ? '10: Maximal effort' : num}
              </Button>
            ))}
            </ButtonGroup>
      <TextField
        label="Duration in Minutes"
        type="number"
        value={newSession.durationInMinutes}
        onChange={(e) => setNewSession({ ...newSession, durationInMinutes: Number(e.target.value) })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Distance in KM"
        type="number"
        value={newSession.distanceInKm}
        onChange={(e) => setNewSession({ ...newSession, distanceInKm: Number(e.target.value) })}
        fullWidth
        margin="normal"
      />
      <Typography variant="body1" mt={2}>
        Time in Zone:
      </Typography>

      <Box display="flex" justifyContent="space-between" mt={2}>
        <TextField
          label="Zone 1"
          type="number"
          value={newSession.intensityZones?.one || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              one: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 2"
          type="number"
          value={newSession.intensityZones?.two || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              two: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 3"
          type="number"
          value={newSession.intensityZones?.three || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              three: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 4"
          type="number"
          value={newSession.intensityZones?.four || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              four: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 5"
          type="number"
          value={newSession.intensityZones?.five || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              five: Number(e.target.value)
            }
          })}
        />
      </Box>


      <Typography variant="body1" mt={2}>
        Feeling During:
      </Typography>
      <ButtonGroup color="primary" aria-label="outlined primary button group" style={{ marginTop: '20px' }}>
        {['lousy', 'poor', 'good', 'great'].map((feeling) => (
          <Button
            key={feeling}
            onClick={() => setNewSession({ ...newSession, feelingDuring: feeling })}
            style={{
              backgroundColor: newSession.feelingDuring === feeling ? '#4fc3f7' : '',
            }}
          >
            {feeling}
          </Button>
        ))}
      </ButtonGroup>

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateSession}
        >
          Create session
        </Button>
      </Box>
      </Box>
    </Box>
</Modal>

</Container>
</DragDropContext>
);
};

export default UserDashOnAdmin;
 