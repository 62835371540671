import React, { useState, useEffect, useRef  } from 'react';
import {
  format,
  startOfWeek,
  eachDayOfInterval,
  addWeeks,
  subWeeks,
  getISOWeek,
  addDays,
} from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { FaSadTear, FaFrown, FaSmile, FaLaughBeam, FaMeh } from 'react-icons/fa';
import jwt_decode from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import {
  Button,
  Typography,
  IconButton,
  Box,
  Modal,
  TextField,
  Container, 
  MenuItem,
  FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HelpIcon from '@mui/icons-material/Help';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { 
  createTrainingSession, deleteTrainingSessionById, updateTrainingSessionById, getUserTrainingSessionsLastTenWeeks, saveWeeklyComment,
  getWeeklyComments, updateWeeklyComments, getDailyMarkersByUserIdAndDate, createDailyMarkerAPI, updateDailyMarkerAPI, getSubStatusByUserId
    } from '../api';
import { TrainingSessions } from '../models/trainingSessionsModelFrontend';
import {WeeklyComments} from '../models/weeklyCommentsModelFrontend';
import { DailyMarker, NewDailyMarker } from './../models/dailyMarkersModelFronted';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';

type PartialIntensityZones = {
  [K in keyof TrainingSessions['intensityZones']]?: TrainingSessions['intensityZones'][K];
};

type PartialTrainingSessions = {
  [K in Exclude<keyof TrainingSessions, 'intensityZones'>]?: TrainingSessions[K];
} & {
  intensityZones?: PartialIntensityZones;
};

interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  role: string;
  // Include any other properties you expect to be in the token
}

const UserDashboard = () => {
  const [trainingSessionsUpdated, setTrainingSessionsUpdated] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [trainingSessions, setTrainingSessions] = useState<TrainingSessions[]>([]);
  const [open, setOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState<TrainingSessions | null>(null);
  const [updatedSession, setUpdatedSession] = useState<PartialTrainingSessions | null>(null);
  const [newSession, setNewSession] = useState<PartialTrainingSessions>({date: new Date(), intensityZones: {}});
  const [loading, setLoading] = useState(false);
  const [disableButtonsBasedOnSubStatus, setDisableButtonsBasedOnSubStatus] = useState<boolean>(true);
  const weekStart = startOfWeek(selectedWeek, { weekStartsOn: 1 });
  const [weeklyComment, setWeeklyComment] = useState('');
  const [weeklyCoachComment, setWeeklyCoachComment] = useState('');
  const [dailyMarkers, setDailyMarkers] = useState<DailyMarker[]>([]);
  const [adminOrCoach, setAdminOrCoach] = useState<boolean>(false);

  const [mood, setMood] = useState<'lousy' | 'poor' | 'good' | 'great' | ''>('');
  const [dialogBoxMoodsShowing, setDialogBoxMoodsShowing] = useState<boolean>(false);
  const [dialogBox2MoodsShowing, setDialogBox2MoodsShowing] = useState<boolean>(false);

  const [checkboxes, setCheckboxes] = useState({
    notRecovered: false,
    justTired: false,
    noMotivation: false,
    stressedOut: false,
    badSleepQuality: false,
    lowSleepTime: false,
    injured: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Check if a valid token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
  
      if (decodedToken.role === "coach" || decodedToken.role === "admin") {
        setAdminOrCoach(true);
        console.log("Admin or Coach");
      } else {
        setAdminOrCoach(false);
        console.log("Not Admin or Coach");
      }
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {

      } else {
        // Token is expired
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
      if (decodedToken.activeSubscription === false) {
        navigate('/subscription', { replace: true });
      } 
    }

    
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const handleSetDailyMarkers = async (newMood: "lousy" | "poor" | "good" | "great", newCheckboxes: any) => {
    const token = localStorage.getItem('token');
        
    if (token === null) {
      console.error("Token not found in local storage");
      return; // End the function if there's no token
    }
  
    const decodedToken = jwt_decode(token) as DecodedToken;
    const userId = decodedToken.id;
  
    const newMarker: NewDailyMarker = {
      userId: userId,
      timestamp: new Date(),
      mood: newMood, // use newMood
      recoveryStatus: newCheckboxes.notRecovered ? "Not recovered" : "", 
      restedStatus: newCheckboxes.justTired ? "Just tired" : "",
      motivationStatus: newCheckboxes.noMotivation ? "No motivation" : "",
      stressStatus: newCheckboxes.stressedOut ? "Stressed out" : "", 
      sleepQualityStatus: newCheckboxes.badSleepQuality ? "Bad sleep quality" : "", 
      sleepQuantityStatus: newCheckboxes.lowSleepTime ? "Low sleep time" : "", 
      injuryStatus: newCheckboxes.injured ? "Injured" : "",
    };
  
    try {
      const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
  
      // Check if a marker for 'today' already exists
      const existingMarker = dailyMarkers.filter(marker => marker !== null && marker !== undefined)
        .find(marker => {
          const timestamp = new Date(marker.timestamp);
          return timestamp.toISOString().split('T')[0] === today;
        });
  
      if (existingMarker) {
        // If marker for 'today' exists, update it
        const updatedMarker = await updateDailyMarkerAPI(existingMarker._id, newMarker); // updateDailyMarkerAPI is a new API function to update a daily marker
        setDailyMarkers(dailyMarkers.map(marker => marker._id === existingMarker._id ? updatedMarker : marker));
      } else {
        const createdMarker = await createDailyMarkerAPI(newMarker);
        setDailyMarkers([...dailyMarkers, createdMarker]);
      }
    } catch (error:any) {
      console.error(error.message);
    }
  
    setDialogBoxMoodsShowing(false);
    setDialogBox2MoodsShowing(false);
  };

  const handleGoodIconClick:any = () => {
    const newCheckboxes = {
      notRecovered: false,
      justTired: false,
      noMotivation: false,
      stressedOut: false,
      badSleepQuality: false,
      lowSleepTime: false,
      injured: false,
    };
    setCheckboxes(newCheckboxes);
  
    const newMood = 'good';
    setMood(newMood);
    handleSetDailyMarkers(newMood, newCheckboxes);
  };
    
  const handleGreatIconClick = () => {
    const newCheckboxes = {
      notRecovered: false,
      justTired: false,
      noMotivation: false,
      stressedOut: false,
      badSleepQuality: false,
      lowSleepTime: false,
      injured: false,
    };
    setCheckboxes(newCheckboxes);
  
    const newMood = 'great';
    setMood(newMood);
    handleSetDailyMarkers(newMood, newCheckboxes);
  };

  const handleWeeklyCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setWeeklyComment(event.target.value);
  };

  const handleSaveWeeklyComment = async () => {
    try {
      const token = localStorage.getItem('token');
          
      if (token === null) {
        console.error("Token not found in local storage");
        return; // End the function if there's no token
      }
  
      const decodedToken = jwt_decode(token) as DecodedToken;
      const userId = decodedToken.id;
  
      const weekNumber = getISOWeek(selectedWeek);
  
      const comments: WeeklyComments[] = await getWeeklyComments(userId, weekNumber);
  
      if (comments.length > 0) {
        // If a comment exists, update it
        const commentId = comments[0]._id; // Assuming the first comment is the one to update
        await updateWeeklyComments(commentId, weeklyComment); // assuming updateWeeklyComments also takes the updated comment as a parameter
      } else {
        // If no comment exists, save a new one
        await saveWeeklyComment(userId, weekNumber, weeklyComment);
      }
  
      // Refresh the comments after saving/updating the comment
      const updatedComments: WeeklyComments[] = await getWeeklyComments(userId, weekNumber);
      setWeeklyComment(updatedComments[0].userComment.valueOf());
    } catch (error) {
      console.error('Failed to save or update comment:', error);
    }
  };
  
  const weekDays = eachDayOfInterval({
    start: weekStart,
    end: addDays(addWeeks(weekStart, 1), -1),
  });

  function getWeekRange(dateInput: Date) {
    const date = new Date(dateInput.valueOf()); // create a copy of the date
    date.setHours(0, 0, 0, 0);
  
    const weekStart = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1); // adjust when day is Sunday
    const weekEnd = weekStart + 6; // end day is the first day + 6
  
    const start = new Date(date.setDate(weekStart));
    const end = new Date(date.setDate(weekEnd));
  
    return { start, end };
  }

  const theme = useTheme();

  const getsubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (token === null) {
        console.error("Token not found in local storage");
        return; // End the function if there's no token
      }

      const decodedToken = jwt_decode(token) as DecodedToken;
      const userId = decodedToken.id;

      const subStatus = await getSubStatusByUserId(userId);
      console.log(subStatus);
      if(subStatus.subscriptionStatus === true) {
        console.log('active')
      } else {
        navigate('/subscription', { replace: true });
        console.log('not active')
      }
    } catch (error:any) {
      console.error(error.message);
    }
  }

  const getActivityIcon = (activity: any) => {
    switch (activity) {
      case 'Running':
      case 'running':
        return <DirectionsRunIcon />;
      case 'Cycling':
      case 'cycling':
        return <DirectionsBikeIcon />;
      case 'swimming':
      case 'Swimming':
        return <PoolIcon />;
      case 'strength':
      case 'Strength':
      case 'StrengthTraining':
        return <FitnessCenterIcon />;
      default:
        return <HelpIcon />;
    }
  };
 
  useEffect(() => {
    const fetchTrainingSessions = async () => {
      try {
        const fetchedTrainingSessions = await getUserTrainingSessionsLastTenWeeks();
  
        if (Array.isArray(fetchedTrainingSessions)) {
          setTrainingSessions(fetchedTrainingSessions);  // Store the fetched training sessions

          // const dailyLoads = calculateDailyTrainingLoad(fetchedTrainingSessions);
          // const dailyLoadsWithEWMAs = calculateAcuteAndChronicLoads(dailyLoads);
          // const dailyLoadsWithACWR = calculateACWR(dailyLoadsWithEWMAs);

          // setTrainingLoadData(dailyLoadsWithACWR);   
        } else {
          console.error('Fetched data is not an array:', fetchedTrainingSessions);
        }
      } catch (error) {
        console.error('Error fetching the training sessions:', error);
      }
    }; 
  
    fetchTrainingSessions();
  }, [trainingSessionsUpdated]);

  useEffect(() => {
    const fetchWeeklyComments = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (token === null) {
          console.error("Token not found in local storage");
          return; // End the function if there's no token
        }
  
        const decodedToken = jwt_decode(token) as DecodedToken;
        const userId = decodedToken.id;
  
        const comments = await getWeeklyComments(userId, getISOWeek(selectedWeek));
        setWeeklyComment(comments[0]?.userComment || '');
        setWeeklyCoachComment(comments[0]?.coachComment || '');
  
        const { start, end } = getWeekRange(selectedWeek);
  
        const dailyMarkers = await getDailyMarkersByUserIdAndDate(userId, start.toISOString(), end.toISOString());
        setDailyMarkers(dailyMarkers);

        // Extracting today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds and ms

        // Finding today's marker
        const todayMarker = dailyMarkers.find((marker: DailyMarker) => {
          const markerDate = new Date(marker.timestamp);
          markerDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds and ms
          return +markerDate === +today;
        });

        // If a marker for today exists, set the mood
        if (todayMarker) {
          setMood(todayMarker.mood);
  
          // Update the checkboxes state
          setCheckboxes(prevState => ({
            notRecovered: !!todayMarker.recoveryStatus,
            justTired: !!todayMarker.restedStatus,
            noMotivation: !!todayMarker.motivationStatus,
            stressedOut: !!todayMarker.stressStatus,
            badSleepQuality: !!todayMarker.sleepQualityStatus,
            lowSleepTime: !!todayMarker.sleepQuantityStatus,
            injured: !!todayMarker.injuryStatus,
          }));
        }

      } catch (error) {
        console.error('Failed to fetch weekly comments:', error);
      }
    };
  
    fetchWeeklyComments();
  }, [selectedWeek]);

  useEffect(() => {
    getsubscriptionStatus();
  }, []); 

  const handleAddIconClick = () => {
    setOpenAddModal(true);
  };

  const handleAddClose = () => {
    setOpenAddModal(false);
    setNewSession({
      date: new Date(),
      activity: '',
      sessionTitle: '',
      sessionDescription: '',
    });
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
        
        if (token === null) {
          console.error("Token not found in local storage");
          return; // End the function if there's no token
        }
  
        const decodedToken = jwt_decode(token) as DecodedToken;
        const userId = decodedToken.id;
      // Set userId and executed values
      const sessionToCreate = {
        ...newSession,
        userId: userId,
        executed: true,
      };
  
      // Call the API to create the new training session
      const createdSession = await createTrainingSession(sessionToCreate);

      if (createdSession) {
        // Update the local state to include the newly created session
        setTrainingSessions((prevSessions) => [...prevSessions, createdSession]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleAddClose();
      // Reset the newSession state
      
    }
  };

  const changeWeek = (direction: string) => {
    setSelectedWeek(
      direction === 'prev'
        ? subWeeks(selectedWeek, 1)
        : addWeeks(selectedWeek, 1),
    );
  };

  const renderWeeks = () => {
    const weeks = [];
    for (let i = 0; i < 3; i++) {
      const week = startOfWeek(subWeeks(new Date(), i), { weekStartsOn: 1 });
      weeks.push(
        <Button
          key={i}
          variant={
            getISOWeek(selectedWeek) === getISOWeek(week)
              ? 'contained'
              : 'outlined'
          }
          sx={{
            backgroundColor:
              getISOWeek(selectedWeek) === getISOWeek(week)
                ? 'primary.main'
                : 'default',
            color: getISOWeek(selectedWeek) === getISOWeek(week)
              ? 'primary.contrastText'
              : 'default',
          }}
          onClick={() => setSelectedWeek(week)}
        >
          {format(week, 'w')}
        </Button>,
      );
    }
    return weeks.reverse();
  };
            
// Add a function to handle the running icon click
const handleRunningIconClick = (session: TrainingSessions) => {
  setSelectedSession(session);
  setUpdatedSession(session);
  setOpen(true);
};

// Add a function to close the modal
const handleClose = () => {
setOpen(false);
};

const handleChange = (field: string, value: any) => {
  if (selectedSession) {
    setUpdatedSession({
      ...updatedSession ?? {},
      [field]: value,
    });
  }
};

const handleFeelingClick = (feeling: string) => {
  handleChange('feelingDuring', feeling);
};

const handleUpdate = async () => {
  if (selectedSession && updatedSession && selectedSession._id) {
    setLoading(true);
    const updatedSessionWithExecuted = { ...updatedSession, executed: true };
    try {
      const updated = await updateTrainingSessionById(selectedSession._id.toString(), updatedSessionWithExecuted);
      if (updated) {
        // Find the index of the updated session in the trainingSessions array
        const updatedSessionIndex = trainingSessions.findIndex(
          (session) => session._id === updated._id
        );

        // Replace the session at the found index with the updated session
        if (updatedSessionIndex > -1) {
          const newTrainingSessions = [...trainingSessions];
          newTrainingSessions[updatedSessionIndex] = updated;
          setTrainingSessions(newTrainingSessions);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  }
};

const handleDelete = async () => {
  if (selectedSession && selectedSession._id) {
    setLoading(true);
    try {
      const deleted = await deleteTrainingSessionById(selectedSession._id.toString() ?? '');
      if (deleted) {
        // Remove the deleted session from the trainingSessions array
        const newTrainingSessions = trainingSessions.filter(
          (session) => session._id !== selectedSession._id
        );
        setTrainingSessions(newTrainingSessions);

        // Close the modal
        
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  }
};

const handleNavigateToCoachDashboard = () => {
  navigate("/admin-dashboard")
};

const getColor = (intensity: any) => {
  const r = Math.round(255 * intensity / 10);
  const g = Math.round(255 - (255 * intensity / 10));
  return `rgb(${r}, ${g}, 0)`;
};

const modalContainerStyles = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    paddingTop: '56px', // for mobile address bar
  },
};

const modalStyles = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '100%',
  maxWidth: '80%',
  minWidth: '30%',
  maxHeight: '80vh', // Maximum height is set to 80% of the viewport height
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'calc(100% - 56px)', // subtract address bar height
    borderRadius: 0,
  },
};

const getMoodIcon = (mood: any) => {
  switch (mood) {
    case 'lousy':
      return <FaSadTear style={{ color: 'lightblue', fontSize: 50 }} />;
    case 'poor':
      return <FaFrown style={{ color: 'lightblue', fontSize: 50 }} />;
    case 'good':
      return <FaSmile style={{ color: 'lightblue', fontSize: 50 }} />;
    case 'great':
      return <FaLaughBeam style={{ color: 'lightblue', fontSize: 50 }} />;
    default:
      return <FaMeh style={{ color: 'lightblue', fontSize: 50 }} />;  // default icon for undefined/empty mood
  }
};

const getTooltipContent = (marker: DailyMarker | undefined) => {
  if (!marker) return '';

  let content = '';
  if (marker.recoveryStatus) content += `${marker.recoveryStatus}\n`;
  if (marker.restedStatus) content += `${marker.restedStatus}\n`;
  if (marker.motivationStatus) content += `${marker.motivationStatus}\n`;
  if (marker.stressStatus) content += `${marker.stressStatus}\n`;
  if (marker.sleepQualityStatus) content += `${marker.sleepQualityStatus}\n`;
  if (marker.sleepQuantityStatus) content += `${marker.sleepQuantityStatus}\n`;
  if (marker.injuryStatus) content += `Injury status: ${marker.injuryStatus}\n`;

  return content;
}        
           
return (
<Container maxWidth="xs">
  <Box
    sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: 2,
    }}
  >
    <Box
      sx={{
      display: 'flex',
      mb: 2,
      }}
    >
      <Button onClick={() => changeWeek('prev')}>Prev</Button>
        {renderWeeks()}
      <Button onClick={() => changeWeek('next')}>Next</Button>
    </Box>
    
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        mb: 2,
      }}
    >
      
      <Tooltip
        title={
          dialogBoxMoodsShowing === true ? (
            <React.Fragment>
              <IconButton 
                onClick={() => setDialogBoxMoodsShowing(false)} 
                style={{ float: 'right' }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Why?</Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.notRecovered} onChange={handleCheckboxChange} name="notRecovered" />}
                  label="Not recovered"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.justTired} onChange={handleCheckboxChange} name="justTired" />}
                  label="Just tired"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.noMotivation} onChange={handleCheckboxChange} name="noMotivation" />}
                  label="No motivation"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.stressedOut} onChange={handleCheckboxChange} name="stressedOut" />}
                  label="Stressed out"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.badSleepQuality} onChange={handleCheckboxChange} name="badSleepQuality" />}
                  label="Bad sleep quality"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.lowSleepTime} onChange={handleCheckboxChange} name="lowSleepTime" />}
                  label="Low sleep time"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.injured} onChange={handleCheckboxChange} name="injured" />}
                  label="Injured"
                />
              </FormGroup>
              <Button 
                onClick={() => {
                  setMood('lousy');
                  handleSetDailyMarkers('lousy', checkboxes )
                }}
                style={{ color: 'white' }}
              >
                Set Daily Markers
              </Button>
            </React.Fragment>
              ) : ''
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={dialogBoxMoodsShowing === true}
          >
            <IconButton onClick={() => {
              setDialogBoxMoodsShowing(true);
              }} size="large">
              <FaSadTear style={{ color: mood === 'lousy' ? 'lightgreen' : 'lightblue', fontSize: 50 }} />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={
              dialogBox2MoodsShowing === true ? (
                <React.Fragment>
                  <IconButton 
                    onClick={() => {
                      setDialogBox2MoodsShowing(false);
                    }} 
                    style={{ float: 'right' }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6">Why?</Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.notRecovered} onChange={handleCheckboxChange} name="notRecovered" />}
                  label="Not recovered"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.justTired} onChange={handleCheckboxChange} name="justTired" />}
                  label="Just tired"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.noMotivation} onChange={handleCheckboxChange} name="noMotivation" />}
                  label="No motivation"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.stressedOut} onChange={handleCheckboxChange} name="stressedOut" />}
                  label="Stressed out"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.badSleepQuality} onChange={handleCheckboxChange} name="badSleepQuality" />}
                  label="Bad sleep quality"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.lowSleepTime} onChange={handleCheckboxChange} name="lowSleepTime" />}
                  label="Low sleep time"
                />
                <FormControlLabel
                  control={<Checkbox checked={checkboxes.injured} onChange={handleCheckboxChange} name="injured" />}
                  label="Injured"
                />
              </FormGroup>
                <Button 
                  onClick={() => {
                    setMood('poor')
                    handleSetDailyMarkers('poor', checkboxes );
                  }}
                  style={{ color: 'white' }}
                >
                  Set Daily Markers
                </Button>
              </React.Fragment>
              ) : ''
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={dialogBox2MoodsShowing === true}
          >
            <IconButton onClick={() => {
              
              setDialogBox2MoodsShowing(true);
              }} size="large">
              <FaFrown style={{ color: mood === 'poor' ? 'lightgreen' : 'lightblue', fontSize: 50 }} />
            </IconButton>
          </Tooltip>

          <IconButton onClick={() => {
            setMood('good');
            handleGoodIconClick();
            }} size="large">
            <FaSmile style={{ color: mood === 'good' ? 'lightgreen' : 'lightblue', fontSize: 50 }} />
          </IconButton>

          <IconButton onClick={() => {
            setMood('great');
            handleGreatIconClick();
            }} size="large">
            <FaLaughBeam style={{ color: mood === 'great' ? 'lightgreen' : 'lightblue', fontSize: 50 }} />
          </IconButton>
        </Box>
        {adminOrCoach && (
      <Box
        sx={{

          mb: 2,
          width: '90%',
          backgroundColor: 'white',
        }}>
          
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={handleNavigateToCoachDashboard}
          >
            Coachs Dashboard
          </Button>
          
      </Box>)}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 2,
        p: [2, 3, 4],
        backgroundColor: '#e0e0e0', // light gray background
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
      }}
    >
    {weekDays.map((day, index) => {
      const dailyMarkerForThisDay = dailyMarkers.find(marker => {
        const markerDate = new Date(marker.timestamp);
        return format(day, 'yyyy-MM-dd') === format(markerDate, 'yyyy-MM-dd');
      });

      const tooltipContent = getTooltipContent(dailyMarkerForThisDay);

      return (
        <Box 
          key={index} 
          mb={2}
          sx={{
            width: '100%',
            borderRadius: '5px',
            p: 2,
            backgroundColor: 'white',
            boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              {format(day, 'EEEE, do MMMM')}
            </Typography>

            {dailyMarkerForThisDay?.mood && (
              <Tooltip title={tooltipContent} arrow>
                <div>
                  {getMoodIcon(dailyMarkerForThisDay?.mood)}
                </div>
              </Tooltip>
            )}
          </div>

          {trainingSessions
            .filter(
              (session) =>
                format(day, 'yyyy-MM-dd') ===
                format(new Date(session.date), 'yyyy-MM-dd'),
            )
            .map((session, sessionIndex) => (
              <IconButton
                key={sessionIndex}
                onClick={() => handleRunningIconClick(session)}
                sx={{
                  mx: 1,
                  backgroundColor: session.executed ? 'lightgreen' : 'initial',
                  border: '1px solid #1976d2', // blue border
                  '&:hover': {
                    backgroundColor: '#1976d2', // blue hover effect
                    color: 'white',
                  },
                }}
              >
                {getActivityIcon(session.activity)}
              </IconButton>
            ))}

          <IconButton 
            onClick={handleAddIconClick} 
            sx={{ 
              mx: 1,
              border: '1px solid #1976d2', // blue border
              '&:hover': {
                backgroundColor: '#1976d2', // blue hover effect
                color: 'white',
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      )
    })}
  <Box
    mb={2}
    sx={{
      width: '100%',
      borderRadius: '5px',
      p: 2,
      backgroundColor: 'white',
      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
    }}>
      <Typography variant="h5" align="center" gutterBottom>
        Weekly Comment
      </Typography>
      
      <TextField
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        placeholder="Write your comment here..."
        value={weeklyComment}
        onChange={handleWeeklyCommentChange}
      />

      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        style={{ marginTop: '10px' }}
        onClick={handleSaveWeeklyComment}
      >
        Save
      </Button>
  </Box>
  <Box
    mb={2}
    sx={{
      width: '100%',
      borderRadius: '5px',
      p: 2,
      backgroundColor: 'white',
      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
    }}>
      <Typography variant="h5" align="center" gutterBottom>
        Coach's comment
      </Typography>
      <Typography variant="body2" align="left" gutterBottom>
        {weeklyCoachComment}
      </Typography>
  </Box>
  
</Box>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="running-session-details"
  aria-describedby="running-session-details-details-description"
>
  <Box
    sx={modalContainerStyles}
  >
    <Box sx={modalStyles}>
    <IconButton
      edge="end"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        color: (theme:any) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    <Typography id="running-session-details" variant="h6" mb={2}>
      {selectedSession ? selectedSession.sessionTitle : ''}
    </Typography>
    <Typography id="running-session-details-description" variant="body1">
      {selectedSession ? selectedSession.sessionDescription : ''}
    </Typography>

    <Typography variant="body1" mt={2}>
      Log your details when executed and save the session.
    </Typography>

    {selectedSession && selectedSession.strengthTraining?.map((exercise, index) => (
        <Box key={index} mt={2}>
          <Typography variant="body1">
            {`${exercise.exercise}:`}
          </Typography>
          <Typography variant="body2">
            {`${exercise.sets} sets of ${exercise.repetitions} reps`}
          </Typography>
          
            </Box>
          ))}
    {selectedSession && (
      <>
        <Typography variant="body1" mt={2}>
          Intensity Rating of Perceived Exertion:
        </Typography>
        <ButtonGroup orientation="vertical" style={{ backgroundColor: "primary" }}
          aria-label="outlined primary button group">
          {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
            <Button
              key={num}
              onClick={() => handleChange('intensityRatingOfPerceivedExertion', num)}
              style={{
                backgroundColor:
                  updatedSession?.intensityRatingOfPerceivedExertion === num ? getColor(num) : '',
                  color:
                newSession.intensityRatingOfPerceivedExertion === num ? "#1a237e" : "#1a237e"
              }}
              >
                {num === 1 ? '1: Very easy' : num === 10 ? '10: Maximal effort' : num}
              </Button>
            ))}
          </ButtonGroup>
          

        <TextField
          label="Duration in Minutes"
          type="number"
          value={updatedSession?.durationInMinutes??''}
          onChange={(e) => handleChange('durationInMinutes', Number(e.target.value))}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Distance in KM"
          type="number"
          value={updatedSession?.distanceInKm??''}
          onChange={(e) => handleChange('distanceInKm', Number(e.target.value))}
          fullWidth
          margin="normal"
        />
        <Typography variant="body1" mt={2}>
          Time in Zone:
        </Typography>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <TextField
            label="Zone 1"
            type="number"
            value={updatedSession?.intensityZones?.one || ''}
            onChange={(e) => setUpdatedSession({ 
              ...updatedSession,
              intensityZones: {
                ...(updatedSession?.intensityZones || {}),
                one: Number(e.target.value)
              }
            })}
          />
          <TextField
            label="Zone 2"
            type="number"
            value={updatedSession?.intensityZones?.two || ''}
            onChange={(e) => setUpdatedSession({ 
              ...updatedSession,
              intensityZones: {
                ...(updatedSession?.intensityZones || {}),
                two: Number(e.target.value)
              }
            })}
          />
          <TextField
            label="Zone 3"
            type="number"
            value={updatedSession?.intensityZones?.three || ''}
            onChange={(e) => setUpdatedSession({ 
              ...updatedSession,
              intensityZones: {
                ...(updatedSession?.intensityZones || {}),
                three: Number(e.target.value)
              }
            })}
          />
          <TextField
            label="Zone 4"
            type="number"
            value={updatedSession?.intensityZones?.four || ''}
            onChange={(e) => setUpdatedSession({ 
              ...updatedSession,
              intensityZones: {
                ...(updatedSession?.intensityZones || {}),
                four: Number(e.target.value)
              }
            })}
          />
          <TextField
            label="Zone 5"
            type="number"
            value={updatedSession?.intensityZones?.five || ''}
            onChange={(e) => setUpdatedSession({ 
              ...updatedSession,
              intensityZones: {
                ...(updatedSession?.intensityZones || {}),
                five: Number(e.target.value)
              }
            })}
          />
        </Box>
        <Typography id="running-session-details-description" variant="body1">
            Comment from athlete:
        </Typography>
        <TextField
            fullWidth
            multiline
            minRows={2}
            value={updatedSession?.comment ?? ''}
            onChange={(e) => handleChange('comment', e.target.value)}
        />

        <Typography variant="body1" mt={2}>
              Feeling During:
        </Typography>
        <ButtonGroup 
            color="primary" 
            aria-label="outlined primary button group"
            style={{ marginTop: '20px' }}  // Adjust this value as needed
            >
            {['lousy', 'poor', 'good', 'great'].map((feeling) => (
                <Button
                key={feeling}
                onClick={() => handleFeelingClick(feeling)}
                style={{
                    backgroundColor: updatedSession?.feelingDuring === feeling ? '#4fc3f7' : '',
                }}
                >
                {feeling}
                </Button>
            ))}
            </ButtonGroup>
            {loading && (
              <Box mt={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleUpdate}
              >
                Log session
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                sx={{ ml: 1 }}
              >
                Delete session
              </Button>
            </Box>
          </>
        )}
        </Box>
      </Box>
</Modal>
<Modal
    open={openAddModal}
    onClose={handleAddClose}
    aria-labelledby="add-session-details"
    aria-describedby="add-session-details-description"
  >
    <Box
    sx={modalContainerStyles}
  >
    <Box sx={modalStyles}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleAddClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: (theme:any) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography id="add-session-details" variant="h6" mb={2}>
        Add New Training Session
      </Typography>

      {/* Add input fields for new training session properties */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            format="dd-MM-yyyy"
            value={newSession.date}
            onChange={(newValue: Date | null) => {
              if (newValue !== null) {
                setNewSession({ ...newSession, date: newValue });
              }
            }}
            
            
          />
        </LocalizationProvider>
        <TextField
            label="Activity"
            select
            value={newSession.activity}
            onChange={(e) => setNewSession({ ...newSession, activity: e.target.value })}
            fullWidth
            margin="normal"
          >
            {/* Add options for activity here */}
            <MenuItem value="Running">Running</MenuItem>
            <MenuItem value="Cycling">Cycling</MenuItem>
            <MenuItem value="Swimming">Swimming</MenuItem>
            <MenuItem value="Strength">Strength</MenuItem>
            <MenuItem value="Circuit-training">Circuit training</MenuItem>
            <MenuItem value="Yoga">Yoga</MenuItem>
            <MenuItem value="Crossfit">Crossfit</MenuItem>
            <MenuItem value="Cross-country-skiing">Cross-country skiing</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
            {/* Add more activities as needed */}
          </TextField>
      <TextField
        label="Session Title"
        value={newSession.sessionTitle}
        onChange={(e) => setNewSession({ ...newSession, sessionTitle: e.target.value })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Session Description"
        value={newSession.sessionDescription}
        onChange={(e) => setNewSession({ ...newSession, sessionDescription: e.target.value })}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
            label="Session comment"
            value={newSession.comment}
            onChange={(e) => setNewSession({ ...newSession, comment: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
        />
      <Typography variant="body1" mt={2}>
        Intensity Rating of Perceived Exertion:
      </Typography>
      <ButtonGroup orientation="vertical" style={{ backgroundColor: "primary" }}
          aria-label="outlined primary button group">
        {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
          <Button
            key={num}
            onClick={() => setNewSession({ ...newSession, intensityRatingOfPerceivedExertion: num })}
            style={{
              backgroundColor:
                newSession.intensityRatingOfPerceivedExertion === num ? getColor(num) : '',
              color:
                newSession.intensityRatingOfPerceivedExertion === num ? "#1a237e" : "#1a237e"
              }}
              >
                {num === 1 ? '1: Very easy' : num === 10 ? '10: Maximal effort' : num}
              </Button>
            ))}
            </ButtonGroup>
      <TextField
        label="Duration in Minutes"
        type="number"
        value={newSession.durationInMinutes}
        onChange={(e) => setNewSession({ ...newSession, durationInMinutes: Number(e.target.value) })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Distance in KM"
        type="number"
        value={newSession.distanceInKm}
        onChange={(e) => setNewSession({ ...newSession, distanceInKm: Number(e.target.value) })}
        fullWidth
        margin="normal"
      />
      <Typography variant="body1" mt={2}>
        Time in Zone:
      </Typography>

      <Box display="flex" justifyContent="space-between" mt={2}>
        <TextField
          label="Zone 1"
          type="number"
          value={newSession.intensityZones?.one || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              one: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 2"
          type="number"
          value={newSession.intensityZones?.two || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              two: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 3"
          type="number"
          value={newSession.intensityZones?.three || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              three: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 4"
          type="number"
          value={newSession.intensityZones?.four || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              four: Number(e.target.value)
            }
          })}
        />
        <TextField
          label="Zone 5"
          type="number"
          value={newSession.intensityZones?.five || 0}
          onChange={(e) => setNewSession({ 
            ...newSession,
            intensityZones: {
              ...(newSession.intensityZones || {}),
              five: Number(e.target.value)
            }
          })}
        />
      </Box>


      <Typography variant="body1" mt={2}>
        Feeling During:
      </Typography>
      <ButtonGroup color="primary" aria-label="outlined primary button group" style={{ marginTop: '20px' }}>
        {['lousy','poor', 'good', 'great'].map((feeling) => (
          <Button
            key={feeling}
            onClick={() => setNewSession({ ...newSession, feelingDuring: feeling })}
            style={{
              backgroundColor: newSession.feelingDuring === feeling ? '#4fc3f7' : '',
            }}
          >
            {feeling}
          </Button>
        ))}
      </ButtonGroup>

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create session
        </Button>
      </Box>
      </Box>
    </Box>
</Modal>

  {/* <IconButton
    color="primary"
    sx={{
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    zIndex: 1,
    boxShadow: '0 3px 5px 2px rgba(105, 105, 105, .7)',
    }}
    onClick={handleChatBubbleClick}
    >
  <BiMessageSquareDetail size={48} />
  </IconButton>
  {chatWindowOpen && <ChatWindow />} */}
</Box>
  <Typography variant="body2" align="left" color="textSecondary">
    Copyright AP-Performance Solutions AS 2023
  </Typography>
</Container>
);
};

export default UserDashboard;
