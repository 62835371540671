import React, { useState, useEffect } from 'react';
import { TextField, Typography, Button, Box,  } from '@mui/material';
import {Select, MenuItem, InputLabel, FormControl, Paper} from '@mui/material';
import { getConnectedAthletes, getQuestionnairesByUserId , updateQuestionnaireById, getSubStatusByUserId} from '../api';
import jwt_decode from 'jwt-decode';
import { Link, useNavigate } from "react-router-dom";
import { Questionnaire } from './../models/questionnaireModelFrontEnd';

interface Athlete {
  userId: string;
  firstName: string;
  lastName: string;
}

interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  // Include any other properties you expect to be in the token
}

const AddQuestionnaireSummaryToUser: React.FC = () => {
  const [summaryInput, setSummaryInput] = useState<string>('');
  const [connectedAthletes, setConnectedAthletes] = useState<Athlete[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if a valid token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {

      } else {
        // Token is expired
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
      if (decodedToken.activeSubscription === false) {
        navigate('/subscription', { replace: true });
      } 
    }

    
  }, []);

  const getsubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (token === null) {
        console.error("Token not found in local storage");
        return; // End the function if there's no token
      }

      const decodedToken = jwt_decode(token) as DecodedToken;
      const userId = decodedToken.id;

      const subStatus = await getSubStatusByUserId(userId);
      console.log(subStatus);
      if(subStatus.subscriptionStatus === true) {
        // navigate('/subscription', { replace: true });
        console.log('active')
      } else {
        navigate('/subscription', { replace: true });
        console.log('not active')
      }
    } catch (error:any) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    getsubscriptionStatus();
  }, []); 

  const handleApplyQuSummary = async () => {
    if (!questionnaire || !summaryInput) {
      alert('Please select a user and enter a summary');
      return;
    }
  
    // Clone the current questionnaire and add the summary
    const updatedQuestionnaire = {
      ...questionnaire,
      summary: summaryInput
    };
  
    try {
      // Call the API to update the questionnaire
      const result = await updateQuestionnaireById(questionnaire._id, updatedQuestionnaire);
      if (result) {
        alert('Questionnaire summary updated successfully');
        setQuestionnaire(updatedQuestionnaire); // Update the local state
      } else {
        alert('Failed to update the questionnaire summary');
      }
    } catch (error) {
      console.error('Error updating questionnaire summary:', error);
      alert('An error occurred while updating the questionnaire summary');
    }
  };
  

  useEffect(() => {
    const fetchConnectedAthletes = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (token === null) {
          console.error("Token not found in local storage");
          return; // End the function if there's no token
        }
  
        const decodedToken = jwt_decode(token) as DecodedToken;
        const userId = decodedToken.id;
  
        const athletes = await getConnectedAthletes(userId);
        console.log(athletes);
        setConnectedAthletes(athletes);
      } catch (error:any) {
        console.error(error.message);
      }
    };
  
    fetchConnectedAthletes();
  }, []);

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      const result = await getQuestionnairesByUserId(selectedUser);
      setQuestionnaire(result);
    };
  
    if (selectedUser) {
      fetchQuestionnaire();
    }
  }, [selectedUser]);

  return (
    <Box m={4}> {/* This adds margins */}
      <Typography variant="h4">Add Questionnaire Summary To User</Typography>
      <FormControl fullWidth variant="outlined">
                <InputLabel id="user-label">Choose User</InputLabel>
                <Select
                  labelId="user-label"
                  label="Choose User"
                  value={selectedUser}
                  onChange={(event) => setSelectedUser(event.target.value as string)}
                >
                  {Array.isArray(connectedAthletes) && connectedAthletes.map((athlete) => (
                    <MenuItem value={athlete.userId}>{athlete.firstName} {athlete.lastName}</MenuItem>
                  ))}
                </Select>
      </FormControl>
      <TextField
        id="summaryInput"
        label="Questionnaire summary"
        multiline
        fullWidth
        variant="outlined"
        value={summaryInput}
        onChange={(e) => setSummaryInput(e.target.value)}
        style={{ marginTop: '20px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleApplyQuSummary}
        style={{ marginTop: '20px' }}
      >
        Apply Questionnaire Summary
      </Button>
      <Paper elevation={3} style={{ padding: '16px' }}>
              <h2>Latest questionnaire on user</h2>

              {questionnaire && questionnaire.questions.map((qa, index) => (
                <>
                <p key={index}>
                  <strong>{qa.question}</strong> 
                </p>
                <p>
                {qa.answer}
                </p>
                </>
              ))}
        </Paper>
    </Box>
  );
};

export default AddQuestionnaireSummaryToUser;
