// App.tsx
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate , useNavigate} from 'react-router-dom';
import NavBar from './components/NavBar';
import AdminDashPage from './pages/AdminDashPage';
import LoginPage from './pages/LoginPage';
import Questionnaire from './pages/QuestionnairePage';
import RegistrationPage from './pages/RegistrationPage';
import SubscriptionPage from './pages/SubscriptionPage';
import UserDashboard from './pages/UserDashPage';
import StatsPage from './pages/StatsPage';
import AddTrainingPlansPage from './pages/AddTrainingPlansPage';
import AddQuestionnaireSummaryToUser from './pages/AddQuestionnaireSummaryToUserPage';
import InvitationsPage from './pages/InvitationsPage';
import SettingsPage from "./pages/SettingsPage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Router>
      <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routes>
        <Route path="/login" element={<LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/registration" element={!isLoggedIn ? <RegistrationPage /> : <Navigate to="/" replace />} />
        <Route path="/" element={isLoggedIn ? <UserDashboard /> : <Navigate to="/login" replace />} />
        <Route path="/admin-dashboard" element={isLoggedIn ? <AdminDashPage /> : <Navigate to="/login" replace />} />
        <Route path="/settings" element={isLoggedIn ? <SettingsPage /> : <Navigate to="/login" replace />} />
        <Route path="/questionnaire" element={isLoggedIn ? <Questionnaire /> : <Navigate to="/login" replace />} />
        <Route path="/subscription" element={isLoggedIn ? <SubscriptionPage /> : <Navigate to="/login" replace />} />
        <Route path="/stats" element={isLoggedIn ? <StatsPage /> : <Navigate to="/login" replace />} />
        <Route path="/admin-dashboard/addPlans" element={isLoggedIn ? <AddTrainingPlansPage /> : <Navigate to="/login" replace />} />
        <Route path="/admin-dashboard/addSummary" element={isLoggedIn ? <AddQuestionnaireSummaryToUser /> : <Navigate to="/login" replace />} />
        <Route path="/invitation" element={isLoggedIn ? <InvitationsPage /> : <Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
