
const API_URL = process.env.REACT_APP_API_URI;

//Auth###############################################################################################################
//#######################################################('/api/auth', authRoutes);################################################################################################
    // router.post('/register', verifyRecaptcha, register);
    // router.post('/login', login);
export const registerUser = async (userData: any) => {
    const response = await fetch(`${API_URL}/api/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    
    return data;
  };

  export const updateUser = async (userData: any, userId: string) => {
    const token = localStorage.getItem('token');

    const response = await fetch(`${API_URL}/api/auth/updateUser/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();
    
    return data;
  };

export const loginUser = async (email: string, password: string) => {
    const response = await fetch(`${API_URL}/api/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      localStorage.setItem('token', data.token);
      return true;
    } else {
      return false;
    }
  };

  export const authenicateUserForRegistration = async (email: string, role: string) => {
    const response = await fetch(`${API_URL}/api/regAuthUser/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, role }),
    });

    const data = await response.json();
    
    return data;
  };

//Chatlog###############################################################################################################


//DailyMarkers###############################################################################################################


//Dietplan###############################################################################################################


//Questionnaire###############################################################################################################

export const addQuestionnaire = async (questionOne: string) => {
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${API_URL}/api/questionnaire/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: userId,
        questionOne: questionOne,
      }),
    });

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//TrainingSessions###############################################################################################################
// router.post('/create', createTrainingSession);
export const createTrainingSessions = async (trainingSessions:any ) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/trainingsessions/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ trainingSessions }),
      
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

  export const saveTrainingPlan = async (trainingSessions: any) => {
    const token = localStorage.getItem('token');
  
    const response = await fetch(`${API_URL}/api/trainingsessions/savePlan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ trainingSessions }),
      
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message);
    }
  
    return data;
  };

//User###############################################################################################################

export const getSubStatusByUserId = async (userId: any) => {
  console.log(userId);
  const token = localStorage.getItem('token');

  const response = await fetch(`${API_URL}/api/user/subStatus/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },    
  });

  const data = await response.json();
  console.log(data);

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

//Weeklyscomments###############################################################################################################



//#####################################################################################################################################################

// Template URLs: 
//#######################################################('/api/chatlog', chatLogRoutes);################################################################################################
// router.post('/create', createChatLog);
// router.get('/', getChatLogs);
// router.get('/:id', getChatLogById);  // route to get a specific chat log by its ID
// router.get('/user/:userId', getChatLogsByUserId);  // route to get all chat logs for a specific user
// router.put('/update/:id', updateChatLog);  // include the chat log ID in the route path
// router.delete('/delete/:id', deleteChatLog);  // include the chat log ID in the route path
// #######################################################//('/api/dailymarkers', dailyMarkersRoutes);################################################################################################
export async function getDailyMarkersByUserIdAndDate(userId: string, startDate: string, endDate: string) {
  const response = await fetch(`${API_URL}/api/dailymarkers/${userId}/${startDate}/${endDate}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch daily markers');
  }

  const data = await response.json();
  return data;
}

export const createDailyMarkerAPI = async (dailyMarker: any) => {
  try {
    const response = await fetch(`${API_URL}/api/dailymarkers/create`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(dailyMarker)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error: any) {
    console.error(error.message);
  }
};

export const updateDailyMarkerAPI = async (id: string, dailyMarker: any) => {
  try {
    const response = await fetch(`${API_URL}/api/dailymarkers/update/${id}`, {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(dailyMarker)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error:any) {
    console.error(error.message);
  }
};

// router.post('/create', createDailyMarker);
// router.get('/', getDailyMarkers);
// router.get('/:id', getDailyMarkerById);
// router.put('/update/:id', updateDailyMarker);
// router.delete('/delete/:id', deleteDailyMarker);
// router.get('/user/:userId', getDailyMarkersByUserId);
// #######################################################//('/api/dietplan', dietPlanRoutes);################################################################################################
// router.post('/create', createDietPlan);
// router.get('/', getDietPlans);
// router.get('/:id', getDietPlanById);
// router.put('/update/:id', updateDietPlan);
// router.delete('/delete/:id', deleteDietPlan);
// router.get('/user/:userId', getDietPlansByUserId);
// #######################################################//('/api/questionnaire', questionnaireRoutes);################################################################################################
// router.post('/create', createQuestionnaire);
// Create a new questionnaire
export const createQuestionnaire = async (questionnaireData:any) => {
  try {
    const response = await fetch(`${API_URL}/api/questionnaire/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // replace 'your_token_here' with your actual token
        'Authorization': `Bearer ${localStorage.getItem('token')}` 
      },
      body: JSON.stringify(questionnaireData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error(`Failed to create questionnaire: ${error}`);
  }
}


// router.get('/', getQuestionnaires);
// router.get('/:id', getQuestionnaireById);
// router.put('/update/:id', updateQuestionnaire);
export const updateQuestionnaireById = async (questionnaireId: any,questionnaireData: any ) => {
  try {
    const response = await fetch(`${API_URL}/api/questionnaire/update/${questionnaireId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // replace 'your_token_here' with your actual token
        'Authorization': `Bearer ${localStorage.getItem('token')}` 
      },
      body: JSON.stringify(questionnaireData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error(`Failed to create questionnaire: ${error}`);
  }
}
// router.delete('/delete/:id', deleteQuestionnaire);
// router.get('/user/:userId', getQuestionnairesByUserId);
// Get questionnaires by user ID
export const getQuestionnairesByUserId = async (userId:any) => {
  try {
    const response = await fetch(`${API_URL}/api/questionnaire/user/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // replace 'your_token_here' with your actual token
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error(`Failed to get questionnaires: ${error}`);
  }
}
// #######################################################//('/api/trainingsessions', trainingSessionsRoutes);################################################################################################
// router.post('/create', createTrainingSession);
export const createTrainingSession = async (trainingSessionData: any) => {
  console.log(trainingSessionData)
  try {
    const response = await fetch(`${API_URL}/api/trainingsessions/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(trainingSessionData),
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error:any) {
    console.error(error.message);
  }
};
// router.get('/', getTrainingSessions);
export const fetchTrainingSessions21Days = async (userId: any) => {
  
  try {
    const response = await fetch(`${API_URL}/api/trainingsessions/user21Days/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error:any) {
    console.error(error.message);
  }
};

export const getUserTrainingSessionsLastTenWeeks = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${API_URL}/api/trainingsessions/last-ten-weeks`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data;
};

export const getUserTrainingSessionsLastTenWeeksByUserId = async (userId:string) => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${API_URL}/api/trainingsessions/last-ten-weeks/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data;
};

export const getUserTrainingSessionsLastFourWeeks = async (userId:string, fromDate: string, toDate: string) => {
  console.log(userId, fromDate, toDate);
  const token = localStorage.getItem('token');

  const response = await fetch(`${API_URL}/api/trainingsessions/last-four-weeks/${userId}/${fromDate}/${toDate}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data;
};

export const updateTrainingSessionById = async (id: string, trainingSessionData: any) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/api/trainingsessions/update/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(trainingSessionData),
  });
  const data = await response.json();
  return data;
};

export const deleteTrainingSessionById = async (id: string) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/api/trainingsessions/delete/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data;
};
// router.get('/:id', getTrainingSessionById);
// router.put('/update/:id', updateTrainingSession);
// router.delete('/delete/:id', deleteTrainingSession);
// router.get('/user/:userId', getTrainingSessionsByUserId);
// #######################################################//('/api/user', userRoutes);################################################################################################
// router.post('/create', createUser);
// router.get('/', getUsers);
// router.get('/:id', getUserById);
// router.put('/update/:id', updateUser);
// router.delete('/delete/:id', deleteUser);
// router.get('/connectedAthletes/:userId', getConnectedAthletesByUserId);
export const getConnectedAthletes = async (userId: string) => {
  const response = await fetch(`${API_URL}/api/user/${userId}/connectedAthletes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });
  
  if (!response.ok) {
    throw new Error(`Server responded with status code ${response.status}`);
  }
  
  const data = await response.json();
  console.log(data);
  return data;
};


// #######################################################//('/api/weeklycomment', weeklyCommentRoutes);################################################################################################
// router.post('/create', createWeeklyComment);
export async function saveWeeklyComment(userId: string, weekNumber: number, comment: string) {
  const response = await fetch(`${API_URL}/api/weeklycomment/create`, {
    method: 'POST',
    body: JSON.stringify({ userId, weekNumber, userComment: comment }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });
  
  if (!response.ok) {
    throw new Error('Failed to save comment');
  }
  
  const data = await response.json();
  
  return data;
}

export async function saveWeeklyCoachComment(userId: string, weekNumber: number, comment: string) {
  const response = await fetch(`${API_URL}/api/weeklycomment/create`, {
    method: 'POST',
    body: JSON.stringify({ userId, weekNumber, coachComment: comment }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });
  
  if (!response.ok) {
    throw new Error('Failed to save comment');
  }
  
  const data = await response.json();
  
  return data;
}

export async function getWeeklyComments(userId: string, weekNumber: number) {
  const response = await fetch(`${API_URL}/api/weeklycomment/${userId}/${weekNumber}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch weekly comments');
  }

  const data = await response.json();

  return data;
}

export async function updateWeeklyComments(commentId: string, weeklyComment: string) {
  const response = await fetch(`${API_URL}/api/weeklycomment/update/${commentId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({ userComment: weeklyComment }) // Pass the updated weekly comment in the body
  });

  if (!response.ok) {
    throw new Error('Failed to update weekly comments');
  }

  const data = await response.json();

  return data;
}

export async function updateWeeklyCoachComments(commentId: string, weeklyComment: string) {
  const response = await fetch(`${API_URL}/api/weeklycomment/update/${commentId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({ coachComment: weeklyComment }) // Pass the updated weekly comment in the body
  });

  if (!response.ok) {
    throw new Error('Failed to update weekly comments');
  }

  const data = await response.json();

  return data;
}
// router.get('/', getWeeklyComments);
// router.get('/:id', getWeeklyCommentById); // route to get a specific weekly comment by its ID
// router.get('/user/:userId', getWeeklyCommentsByUserId); // route to get all weekly comments for a specific user
// router.put('/update/:id', updateWeeklyComment); // include the weekly comment ID in the route path
// router.delete('/delete/:id', deleteWeeklyComment); // include the weekly comment ID in the route path


// #######################################################//INVITATIONS##########################################
export const getInvitations = async (userId: string) => {
  const response = await fetch(`${API_URL}/api/user/${userId}/pendingInvitations`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });
  const data = await response.json();
  
  return data;
};


export const acceptInvitation = async (inviterId: string) => {
  const response = await fetch(`${API_URL}/api/invitations/accept`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({ inviterId }),
  });

  return response; // Return the response directly
};

export const declineInvitation = async (inviterId: string) => {
  const response = await fetch(`${API_URL}/api/invitations/decline`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({ inviterId }),
  });
  const data = await response.json();
  
  return data;
};

export const sendInvitation = async (email: string) => {
  try {
    const response = await fetch(`${API_URL}/api/invitations/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ email }),
    });
    // Depending on your API you might want to return the JSON response,
    // but here we're just returning the whole response object
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

//////////////////////////////////////////////////// GPT

export const createTrainingPlan = async (selectedUser: string, gptRoleContent: string, message: string) => {
  const response = await fetch(`${API_URL}/api/trainingsessions/createPlan`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({selectedUser, gptRoleContent, message }),
  });
  const data = await response.json();
  
  return data;
};

export const indentifyUsersInNeedOfFeedback = async (gptRoleContent: string, message: string) => {
  const response = await fetch(`${API_URL}/api/user/identify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({gptRoleContent, message }),
  });
  const data = await response.json();
  
  return data;
};

///////////////////////////////////////////////////////////////////// STRIPE
export const createStripeCheckoutSession = async (email: string, firstName: string, lastName: string, subscriptionType: string) => {
  try {
    const response = await fetch(`${API_URL}/api/stripe/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        email, 
        firstName,
        lastName,
        subscriptionType,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to create Stripe Checkout session');
    }

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

////////////////////////////////////////////////////// get one user
export const getUserById = async (userId: string) => {
  const response = await fetch(`${API_URL}/api/user/${userId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });

  const data = await response.json();
  console.log(data);
  
  return data;
};