import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { TextField, Typography, Button, Box, Chip, Autocomplete,Grid, Card, CardContent, Tooltip  } from '@mui/material';
import jwt_decode from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import { format, subDays, addDays, differenceInDays } from 'date-fns';
import { styled } from '@mui/system';
import { DailyMarker, NewDailyMarker } from './../models/dailyMarkersModelFronted';
import { getConnectedAthletes, createTrainingSession, fetchTrainingSessions21Days, getDailyMarkersByUserIdAndDate, getSubStatusByUserId } from '../api';
import { TrainingSessionData } from './../models/trainingSessionsModelFrontend';
import { PieChart, Pie, Cell } from 'recharts';
import { Tooltip as RechartsTooltip } from 'recharts';
import { utcToZonedTime } from 'date-fns-tz'
import { FaSadTear, FaFrown, FaSmile, FaLaughBeam, FaMeh } from 'react-icons/fa';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HelpIcon from '@mui/icons-material/Help';

interface Athlete {
  userId: string;
  firstName: string;
  lastName: string;
}

interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  // Include any other properties you expect to be in the token
}

interface Zones {
  [key: string]: number;
}

const AddTrainingPlansPage: React.FC = () => {
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
  const [jsonInput, setJsonInput] = useState<string>('');
  const [trainingSessions, setTrainingSessions] = useState<TrainingSessionData[]>([]);
  const [connectedAthletes, setConnectedAthletes] = useState<Athlete[]>([]);
  const [gridDates, setGridDates] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const INTENSITY_ZONE_COLORS = ['#008000', '#bfff00', '#ffff00', '#ff7f00', '#ff0000'];
  const [dailyMarkers, setDailyMarkers] = useState<DailyMarker[]>([]);
  
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a valid token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {

      } else {
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
      if (decodedToken.activeSubscription === false) {
        navigate('/subscription', { replace: true });
      } 
    }

    
  }, []);

  const getsubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (token === null) {
        console.error("Token not found in local storage");
        return; // End the function if there's no token
      }

      const decodedToken = jwt_decode(token) as DecodedToken;
      const userId = decodedToken.id;

      const subStatus = await getSubStatusByUserId(userId);
      console.log(subStatus);
      if(subStatus.subscriptionStatus === true) {
        // navigate('/subscription', { replace: true });
        console.log('active')
      } else {
        navigate('/subscription', { replace: true });
        console.log('not active')
      }
    } catch (error:any) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    getsubscriptionStatus();
  }, []); 


  useEffect(() => {
    const fetchConnectedAthletes = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (token === null) {
          console.error("Token not found in local storage");
          return; // End the function if there's no token
        }
  
        const decodedToken = jwt_decode(token) as DecodedToken;
        const userId = decodedToken.id;
  
        const athletes = await getConnectedAthletes(userId);
        console.log(athletes);
        setConnectedAthletes(athletes);
      } catch (error:any) {
        console.error(error.message);
      }
    };
  
    fetchConnectedAthletes();
  }, []);

  useEffect(() => {
    const today = new Date();
    const startDate = subDays(today, 21); // Start date is "today - 21 days"
    let lastDate = today;
  
    // Find the last session date
    for (const session of trainingSessions) {
      const sessionDate = utcToZonedTime(session.date, Intl.DateTimeFormat().resolvedOptions().timeZone);
      if (sessionDate > lastDate) {
        lastDate = sessionDate;
      }
    }
  
    lastDate = addDays(lastDate, 1); // Add one day to the last date
  
    // Create an array of dates from startDate to lastDate
    const dates: string[] = [];
    for (let date = startDate; date <= lastDate; date = addDays(date, 1)) {
      dates.push(format(utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone), 'yyyy-MM-dd')); 
    }

    setGridDates(dates);
  }, [trainingSessions]);

  const handleFetchTrainingPlans = async () => {
    if (selectedAthletes.length > 0) {
      setIsLoading(true); // Set loading state to true
      const athleteUserId = selectedAthletes[0].userId;
  
      const sessions = await fetchTrainingSessions21Days(athleteUserId);
      alert("Fetching " + selectedAthletes[0].firstName + "'s last 21 days of training and future plan");
      setTrainingSessions(sessions);
      console.log(sessions);

      const end = new Date();
      const start = new Date();
      start.setDate(end.getDate() - 21);

      const dailyMarkers = await getDailyMarkersByUserIdAndDate(athleteUserId, start.toISOString(), end.toISOString());
      setDailyMarkers(dailyMarkers);
       
      setIsLoading(false); 
    } else {
      alert('No user selected');
    }
  };

  const handleApplyTrainingPlans = async (): Promise<void> => {
    try {
      const trainingSessionData: TrainingSessionData[] = JSON.parse(jsonInput);
      console.log(trainingSessionData);
    
      for (const athlete of selectedAthletes) {
        for (const data of trainingSessionData) {
          const newTrainingSession: TrainingSessionData = {
            ...data,
            userId: athlete.userId,
          };
          console.log(newTrainingSession);
  
          // Replace this with a call to your API that creates a new training session
          await createTrainingSession(newTrainingSession);
        } 
      }
    } catch (error:any) {
      console.error(error);
    }
  };

  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
  };

  const getColor = (intensity: 'low' | 'medium' | 'high' | 'exhaustive') => {
    switch (intensity) {
      case 'low':
        return 'lightgreen';
      case 'medium':
        return 'yellow';
      case 'high':
        return 'orange';
      case 'exhaustive':
        return 'red';
      default:
        return 'white'; // fallback color
    }
  };

  const getActivityIcon = (activity: any) => {
    switch (activity) {
      case 'Running':
      case 'running':
        return <DirectionsRunIcon />;
      case 'Cycling':
      case 'cycling':
        return <DirectionsBikeIcon />;
      case 'swimming':
      case 'Swimming':
        return <PoolIcon />;
      case 'strength':
      case 'Strength':
      case 'StrengthTraining':
        return <FitnessCenterIcon />;
      default:
        return <HelpIcon />;
    }
  };

  const getMoodIcon = (mood: any) => {
    switch (mood) {
      case 'lousy':
        return <FaSadTear style={{ color: 'lightblue', fontSize: 50 }} />;
      case 'poor':
        return <FaFrown style={{ color: 'lightblue', fontSize: 50 }} />;
      case 'good':
        return <FaSmile style={{ color: 'lightblue', fontSize: 50 }} />;
      case 'great':
        return <FaLaughBeam style={{ color: 'lightblue', fontSize: 50 }} />;
      default:
        return <FaMeh style={{ color: 'lightblue', fontSize: 50 }} />;  // default icon for undefined/empty mood
    }
  };

  const getTooltipContent = (marker: DailyMarker | undefined) => {
    if (!marker) return '';
  
    let content = '';
    if (marker.recoveryStatus) content += `${marker.recoveryStatus}\n`;
    if (marker.restedStatus) content += `${marker.restedStatus}\n`;
    if (marker.motivationStatus) content += `${marker.motivationStatus}\n`;
    if (marker.stressStatus) content += `${marker.stressStatus}\n`;
    if (marker.sleepQualityStatus) content += `${marker.sleepQualityStatus}\n`;
    if (marker.sleepQuantityStatus) content += `${marker.sleepQuantityStatus}\n`;
    if (marker.injuryStatus) content += `Injury status: ${marker.injuryStatus}\n`;
  
    return content;
  }    

  return (
    <Box m={4}> {/* This adds margins */}
      <Typography variant="h4">Add Training Plans</Typography>
      <Autocomplete
          multiple
          id="athletes-select"
          options={connectedAthletes}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`} // assuming the athlete has firstName and lastName properties
          value={selectedAthletes}
          onChange={(_, newValue) => setSelectedAthletes(newValue)}
          renderTags={(value: Athlete[], getTagProps) =>
            value.map((option: Athlete, index: number) => (
              <Chip variant="outlined" label={`${option.firstName} ${option.lastName}`} {...getTagProps({ index })} />
            ))
          }
          style={{ width: '100%', marginTop: '20px' }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Athletes" placeholder="Select athletes" />
          )}
        />
      <TextField
        id="json-input"
        label="JSON Input"
        multiline
        minRows={20}
        maxRows={20}
        fullWidth
        variant="outlined"
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        style={{ marginTop: '20px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleApplyTrainingPlans}
        style={{ marginTop: '20px' }}
      >
        Apply Training Plans
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleFetchTrainingPlans}
        style={{ marginTop: '20px', marginLeft: '5px' }}
      >
        Fetch selected user's sessions
      </Button>
      {isLoading ? (
        <Typography variant="body2">Loading sessions...</Typography> // Display a loading message while fetching sessions
      ) : (
      <Grid container spacing={2}>
          {gridDates.map(date => {
            const sessions = trainingSessions.filter(session => {
              return format(new Date(session.date), 'yyyy-MM-dd') === date;
            });
            const totalDuration = sessions.reduce((total, session) => total + (session.durationInMinutes || 0), 0);
              
            const intensityZonesSum = sessions.reduce((zones: Zones, session) => {
              if (session.intensityZones) { // Check if intensityZones property exists
                // Object.keys(session.intensityZones).forEach((zoneKey) => {
                //   if (!zones[zoneKey]) {
                //     zones[zoneKey] = 0;
                //   }
                //   zones[zoneKey] += session.intensityZones[zoneKey as keyof typeof session.intensityZones];
                // });
              }
              return zones;
            }, {});

            const pieChartData = Object.keys(intensityZonesSum).map((zone, index) => ({
              name: zone,
              value: intensityZonesSum[zone],
              color: INTENSITY_ZONE_COLORS[index % INTENSITY_ZONE_COLORS.length],
            }));

            // Finding the daily marker for this date
            const dailyMarkerForThisDay = dailyMarkers.find(marker => {
              const markerDate = new Date(marker.timestamp);
              return format(markerDate, 'yyyy-MM-dd') === date;
            });

            // Preparing the tooltip content
            const tooltipContent = getTooltipContent(dailyMarkerForThisDay);

            return (
              <Grid item xs={"auto"} sm={"auto"} md={2} lg={2} key={date}>
                <Card variant="outlined">
                
                  <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                          {date}
                        </Typography>

                        {/* Here we use the daily marker for this date */}
                        
                    </div>
                    <Typography variant="body2">Total duration: {formatDuration(totalDuration)}</Typography>
                    {sessions.map(session => (
                      <Tooltip
                        title={
                          <React.Fragment>
                            <div>Description: {session.sessionDescription}</div>
                            <div>Comment: {session.comment}</div>
                            <div>Intensity RPE: {session.intensityRatingOfPerceivedExertion}</div>
                            <div>Duration: {formatDuration(session.durationInMinutes)}</div>
                            <div>Distance: {session.distanceInKm} km</div>
                            <div>Strength Training: 
                              {session.strengthTraining.map(st => 
                                <React.Fragment key={st.exercise}>
                                  <div>{`${st.exercise}, Sets: ${st.sets}, Reps: ${st.repetitions}, Load: ${st.load} kg`}</div>
                                </React.Fragment>
                              )}
                            </div>
                          </React.Fragment>
                        }
                        key={session._id}
                        
                      >
                        <Box display="flex" alignItems="center" mt={2}>
                          <Box bgcolor={getColor(session.plannedIntensity)} p={1} borderRadius="50%">
                            {getActivityIcon(session.activity)}
                          </Box>
                          <Typography variant="body2" ml={2}>{session.sessionTitle}</Typography>
                        </Box>
                      </Tooltip>
                    ))}
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <PieChart width={100} height={100}>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        outerRadius={40}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <RechartsTooltip />
                    </PieChart>
                    {dailyMarkerForThisDay?.mood && (
                          <Tooltip title={tooltipContent} arrow>
                            <div>
                              {getMoodIcon(dailyMarkerForThisDay?.mood)}
                            </div>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
          <Grid container spacing={3}>
              <Grid item xs={3}>
              <Card variant="outlined">
                    <CardContent>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Typography variant="h6">Average Time in Zones per Week</Typography>
                        {/* Generate pie chart data for all executed sessions over 3 weeks */}
                        {(() => {
                          const executedSessions = trainingSessions.filter(session => session.executed);
                          const weeklyIntensityZonesSum = executedSessions.reduce((zones: Zones, session) => {
                            if (session.intensityZones) { // Check if intensityZones property exists
                              // Object.keys(session.intensityZones).forEach((zoneKey) => {
                              //   if (!zones[zoneKey]) {
                              //     zones[zoneKey] = 0;
                              //   }
                              //   zones[zoneKey] += session.intensityZones[zoneKey as keyof typeof session.intensityZones];
                              // });
                            }
                            return zones;
                          }, {});

                          // Average over 3 weeks
                          Object.keys(weeklyIntensityZonesSum).forEach((zoneKey) => {
                            weeklyIntensityZonesSum[zoneKey] /= 3;
                          });

                          const pieChartData = Object.keys(weeklyIntensityZonesSum).map((zone, index) => ({
                            name: zone,
                            value: weeklyIntensityZonesSum[zone],
                            color: INTENSITY_ZONE_COLORS[index % INTENSITY_ZONE_COLORS.length],
                          }));

                          return (
                            <PieChart width={100} height={100}>
                              <Pie
                                data={pieChartData}
                                cx="50%"
                                cy="50%"
                                outerRadius={40}
                                fill="#8884d8"
                                dataKey="value"
                              >
                                {pieChartData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                              </Pie>
                              <RechartsTooltip />
                            </PieChart>
                          );
                        })()}
                      </Box>
                    </CardContent>
              </Card>
                              
              <Card variant="outlined">
                    <CardContent>
                      <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
                        <Typography variant="h6">Average sessions per week</Typography>
                        {/* Calculate the average number of executed sessions per week */}
                        <Typography variant="body1">
                          {(() => {
                            const executedSessionsCount = trainingSessions.filter(session => session.executed).length;
                            const averageSessionsPerWeek = executedSessionsCount / 3;
                            return averageSessionsPerWeek.toFixed(2); // Display with 2 decimal places
                          })()}
                        </Typography>

                        <Typography variant="h6">Average sessions per week per activity</Typography>
                        {/* Calculate the average number of executed sessions per week for each activity */}
                        {(() => {
                          const activities = new Set(trainingSessions.map(session => session.activity)); // Get unique activities
                          const averagesPerActivity: { [activity: string]: number } = {};

                          activities.forEach(activity => {
                            const executedSessionsCount = trainingSessions.filter(session => session.executed && session.activity === activity).length;
                            averagesPerActivity[activity] = executedSessionsCount / 3;
                          });

                          return Object.entries(averagesPerActivity).map(([activity, average]) => (
                            <Typography key={activity} variant="body1">{`${activity}: ${average.toFixed(2)}`}</Typography>
                          ));
                        })()}

                        <Typography variant="h6">Average hours per week</Typography>
                        {/* Calculate the average hours per week for all executed sessions */}
                        <Typography variant="body1">
                          {(() => {
                            const executedSessions = trainingSessions.filter(session => session.executed);
                            const totalHours = executedSessions.reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                            const averageHoursPerWeek = totalHours / 3;
                            return averageHoursPerWeek.toFixed(2); // Display with 2 decimal places
                          })()}
                        </Typography>

                        <Typography variant="h6">Average hours per week per activity</Typography>
                        {/* Calculate the average hours per week for each activity */}
                        {(() => {
                          const executedSessions = trainingSessions.filter(session => session.executed);
                          const activities = new Set(executedSessions.map(session => session.activity)); // Get unique activities
                          const averagesPerActivity: { [activity: string]: number } = {};

                          activities.forEach(activity => {
                            const totalHours = executedSessions.filter(session => session.activity === activity).reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                            averagesPerActivity[activity] = totalHours / 3;
                          });

                          return Object.entries(averagesPerActivity).map(([activity, average]) => (
                            <Typography key={activity} variant="body1">{`${activity}: ${average.toFixed(2)}`}</Typography>
                          ));
                        })()}

                        <Typography variant="h6">Average hours per day</Typography>
                        {/* Calculate the average hours per day for all executed sessions */}
                        <Typography variant="body1">
                          {(() => {
                            const executedSessions = trainingSessions.filter(session => session.executed);
                            const totalHours = executedSessions.reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                            const averageHoursPerDay = totalHours / (3 * 7); // three weeks, 7 days per week
                            return averageHoursPerDay.toFixed(2); // Display with 2 decimal places
                          })()}
                        </Typography>
                      </Box>
                    </CardContent>
              </Card>
              </Grid>
              <Grid item xs={3}>           
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography variant="h6">Average Time in Zones per Week (Future)</Typography>
                    {/* Generate pie chart data for all future sessions over 3 weeks */}
                    {(() => {
                      const futureSessions = trainingSessions.filter(session => new Date(session.date) >= new Date() && !session.executed);
                      const maxDate = futureSessions.reduce((max, session) => new Date(session.date) > max ? new Date(session.date) : max, new Date());
                      const durationInWeeks = Math.ceil((maxDate.getTime() - new Date().getTime()) / (7 * 24 * 60 * 60 * 1000));
                      const weeklyIntensityZonesSum = futureSessions.reduce((zones: Zones, session) => {
                        if (session.intensityZones) {
                          // Object.keys(session.intensityZones).forEach((zoneKey) => {
                          //   if (!zones[zoneKey]) {
                          //     zones[zoneKey] = 0;
                          //   }
                          //   zones[zoneKey] += session.intensityZones[zoneKey as keyof typeof session.intensityZones];
                          // });
                        }
                        return zones;
                      }, {});

                      Object.keys(weeklyIntensityZonesSum).forEach((zoneKey) => {
                        weeklyIntensityZonesSum[zoneKey] /= durationInWeeks;
                      });

                      const pieChartData = Object.keys(weeklyIntensityZonesSum).map((zone, index) => ({
                        name: zone,
                        value: weeklyIntensityZonesSum[zone],
                        color: INTENSITY_ZONE_COLORS[index % INTENSITY_ZONE_COLORS.length],
                      }));

                      return (
                        <PieChart width={100} height={100}>
                          <Pie
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            outerRadius={40}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {pieChartData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <RechartsTooltip />
                        </PieChart>
                      );
                    })()}
                  </Box>
                </CardContent>
              </Card>

              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
                    <Typography variant="h6">Average sessions per week (Future)</Typography>
                    {/* Calculate the average number of future sessions per week */}
                    <Typography variant="body1">
                      {(() => {
                        const futureSessions = trainingSessions.filter(session => new Date(session.date) >= new Date() && !session.executed);
                        const maxDate = futureSessions.reduce((max, session) => new Date(session.date) > max ? new Date(session.date) : max, new Date());
                        const durationInWeeks = Math.ceil((maxDate.getTime() - new Date().getTime()) / (7 * 24 * 60 * 60 * 1000));
                        const futureSessionsCount = futureSessions.length;
                        const averageSessionsPerWeek = futureSessionsCount / durationInWeeks;
                        return averageSessionsPerWeek.toFixed(2); // Display with 2 decimal places
                      })()}
                    </Typography>

                    <Typography variant="h6">Average sessions per week per activity (Future)</Typography>
                    {/* Calculate the average number of future sessions per week for each activity */}
                    {(() => {
                      const futureSessions = trainingSessions.filter(session => new Date(session.date) >= new Date() && !session.executed);
                      const maxDate = futureSessions.reduce((max, session) => new Date(session.date) > max ? new Date(session.date) : max, new Date());
                      const durationInWeeks = Math.ceil((maxDate.getTime() - new Date().getTime()) / (7 * 24 * 60 * 60 * 1000));
                      const activities = new Set(futureSessions.map(session => session.activity)); // Get unique activities
                      const averagesPerActivity: { [activity: string]: number } = {};

                      activities.forEach(activity => {
                        const futureSessionsCount = futureSessions.filter(session => session.activity === activity).length;
                        averagesPerActivity[activity] = futureSessionsCount / durationInWeeks;
                      });

                      return Object.entries(averagesPerActivity).map(([activity, average]) => (
                        <Typography key={activity} variant="body1">{`${activity}: ${average.toFixed(2)}`}</Typography>
                      ));
                    })()}

                    <Typography variant="h6">Average hours per week (Future)</Typography>
                    {/* Calculate the average hours per week for all future sessions */}
                    <Typography variant="body1">
                      {(() => {
                        const futureSessions = trainingSessions.filter(session => new Date(session.date) >= new Date() && !session.executed);
                        const maxDate = futureSessions.reduce((max, session) => new Date(session.date) > max ? new Date(session.date) : max, new Date());
                        const durationInWeeks = Math.ceil((maxDate.getTime() - new Date().getTime()) / (7 * 24 * 60 * 60 * 1000));
                        const totalHours = futureSessions.reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                        const averageHoursPerWeek = totalHours / durationInWeeks;
                        return averageHoursPerWeek.toFixed(2); // Display with 2 decimal places
                      })()}
                    </Typography>

                    <Typography variant="h6">Average hours per week per activity (Future)</Typography>
                    {/* Calculate the average hours per week for each activity in future */}
                    {(() => {
                      const futureSessions = trainingSessions.filter(session => new Date(session.date) >= new Date() && !session.executed);
                      const maxDate = futureSessions.reduce((max, session) => new Date(session.date) > max ? new Date(session.date) : max, new Date());
                      const durationInWeeks = Math.ceil((maxDate.getTime() - new Date().getTime()) / (7 * 24 * 60 * 60 * 1000));
                      const activities = new Set(futureSessions.map(session => session.activity)); // Get unique activities
                      const averagesPerActivity: { [activity: string]: number } = {};

                      activities.forEach(activity => {
                        const totalHours = futureSessions.filter(session => session.activity === activity).reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                        averagesPerActivity[activity] = totalHours / durationInWeeks;
                      });

                      return Object.entries(averagesPerActivity).map(([activity, average]) => (
                        <Typography key={activity} variant="body1">{`${activity}: ${average.toFixed(2)}`}</Typography>
                      ));
                    })()}

                    <Typography variant="h6">Average hours per day (Future)</Typography>
                    {/* Calculate the average hours per day for all future sessions */}
                    <Typography variant="body1">
                      {(() => {
                        const futureSessions = trainingSessions.filter(session => new Date(session.date) >= new Date() && !session.executed);
                        const maxDate = futureSessions.reduce((max, session) => new Date(session.date) > max ? new Date(session.date) : max, new Date());
                        const durationInWeeks = Math.ceil((maxDate.getTime() - new Date().getTime()) / (7 * 24 * 60 * 60 * 1000));
                        const totalHours = futureSessions.reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                        const averageHoursPerDay = totalHours / (durationInWeeks * 7); // number of weeks times 7 days per week
                        return averageHoursPerDay.toFixed(2); // Display with 2 decimal places
                      })()}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              </Grid> 
                  
            
        </Grid>
      </Grid>
      )}
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
      {/* Training sessions */}
      <Grid item xs={12}>
        <Typography variant="h6">Training Sessions</Typography>
        <TableContainer component={Paper} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Planned Intensity</TableCell>
                <TableCell>Activity</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>RPE</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Distance (km)</TableCell>
                <TableCell>Intensity Zones</TableCell>
                <TableCell>Strength Training</TableCell>
                <TableCell>Feeling</TableCell>
                <TableCell>Executed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainingSessions.map((session) => (
                <TableRow key={session._id}>
                  <TableCell>{format(new Date(session.date), 'yyyy-MM-dd')}</TableCell>
                  <TableCell>{session.plannedIntensity}</TableCell>
                  <TableCell>{session.activity}</TableCell>
                  <TableCell>{session.sessionTitle}</TableCell>
                  <TableCell>{session.sessionDescription}</TableCell>
                  <TableCell>{session.comment}</TableCell>
                  <TableCell>{session.intensityRatingOfPerceivedExertion}</TableCell>
                  <TableCell>{session.durationInMinutes}</TableCell>
                  <TableCell>{session.distanceInKm}</TableCell>
                  <TableCell>{JSON.stringify(session.intensityZones)}</TableCell>
                  <TableCell>{JSON.stringify(session.strengthTraining)}</TableCell>
                  <TableCell>{session.feelingDuring}</TableCell>
                  <TableCell>{session.executed ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Daily markers moods */}
      <Grid item xs={12}>
      <Typography variant="h6">Moods</Typography>
      <TableContainer component={Paper} style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Mood</TableCell>
              <TableCell>Additional info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dailyMarkers.map((marker) => (
              <TableRow key={format(new Date(marker.timestamp), 'yyyy-MM-dd')}>
                <TableCell>{format(new Date(marker.timestamp), 'yyyy-MM-dd')}</TableCell>
                <TableCell>{marker.mood}</TableCell>
                <TableCell>{marker.injuryStatus}, 
                {marker.recoveryStatus},
                {marker.motivationStatus},
                {marker.sleepQualityStatus},
                {marker.sleepQuantityStatus},
                {marker.stressStatus}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '20px' }}>
      {/* Training sessions */}
      <Grid item xs={12}>
        <Typography variant="h6">How to get ChatGPT to write you a trainingplan:</Typography>
        <Typography variant="h6">Step 1: Provide the athletes questionnaire by copying it from the admin-dashboard.</Typography>
        <Typography variant="h6">Step 2: Provide the list of executed sessions (optional)</Typography>
        <Typography variant="h6">Step 3: Provide the list of dates and moods (optional)</Typography>
        <Typography variant="h6">Step 4: Tell chat GPT to make a plan for a certain time period (f.ex. 4 weeks) based on the information 
        you have provided and any other information that you find relevant for the upcoming trainingperiod. </Typography>
        <Typography variant="h6">Step 5: Discuss it and improve it together with ChatGPT (only gpt-4)</Typography>
        <Typography variant="h6">Step 6: Tell it to provide the plan "In a valid JSON that is formatted like this:</Typography>
        <p>
        <pre>
        <code>
        {`
        [{
          date: Date;
          plannedIntensity: 'low'| 'medium' | 'high' | 'exhaustive';
          activity: string;
          sessionTitle: string;
          sessionDescription: string;
          durationInMinutes: number;
          distanceInKm: number;
          strengthTraining: [
              {
                exercise: string;
                sets: number;
                repetitions: number;
                load: number;
              }
            ];
          }]
        `}
        </code>
        </pre>
        </p>
      </Grid>
    </Grid>
    </Box>
  );
};

export default AddTrainingPlansPage;
            {/* THIS GRID CALCULTED FUTURE SESSIONS: NEED SOM WORK ON "PER WEEK AVERAGE CALC"=> How do we find "WEEKSnumber"
            <Grid item xs={4} sm={4} md={4} lg={4}>          
              
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography variant="h6">Average Time in Zones per Week (Future)</Typography>
                    {/* Generate pie chart data for all upcoming sessions 
                    {(() => {
                      const futureSessions = trainingSessions.filter(session => !session.executed && new Date(session.date) > new Date());
                      const weeklyIntensityZonesSum = futureSessions.reduce((zones: Zones, session) => {
                        if (session.intensityZones) { // Check if intensityZones property exists
                          Object.keys(session.intensityZones).forEach((zoneKey) => {
                            if (!zones[zoneKey]) {
                              zones[zoneKey] = 0;
                            }
                            zones[zoneKey] += session.intensityZones[zoneKey as keyof typeof session.intensityZones];
                          });
                        }
                        return zones;
                      }, {});

                      // Average over 3 weeks
                      Object.keys(weeklyIntensityZonesSum).forEach((zoneKey) => {
                        weeklyIntensityZonesSum[zoneKey] /= 3;
                      });

                      const pieChartData = Object.keys(weeklyIntensityZonesSum).map((zone, index) => ({
                        name: zone,
                        value: weeklyIntensityZonesSum[zone],
                        color: INTENSITY_ZONE_COLORS[index % INTENSITY_ZONE_COLORS.length],
                      }));

                      return (
                        <PieChart width={100} height={100}>
                          <Pie
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            outerRadius={40}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {pieChartData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <RechartsTooltip />
                        </PieChart>
                      );
                    })()}
                  </Box>
                </CardContent>
              </Card>

              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
                    <Typography variant="h6">Future sessions per week</Typography>
                    {/* Calculate the number of upcoming sessions per week 
                    <Typography variant="body1">
                      {(() => {
                        const futureSessionsCount = trainingSessions.filter(session => !session.executed && new Date(session.date) > new Date()).length;
                        const averageSessionsPerWeek = futureSessionsCount / 3;
                        return averageSessionsPerWeek.toFixed(2); // Display with 2 decimal places
                      })()}
                    </Typography>

                    <Typography variant="h6">Future sessions per week per activity</Typography>
                    {/* Calculate the number of upcoming sessions per week for each activity 
                    {(() => {
                      const activities = new Set(trainingSessions.map(session => session.activity)); // Get unique activities
                      const averagesPerActivity: { [activity: string]: number } = {};

                      activities.forEach(activity => {
                        const futureSessionsCount = trainingSessions.filter(session => !session.executed && session.activity === activity && new Date(session.date) > new Date()).length;
                        averagesPerActivity[activity] = futureSessionsCount / 3;
                      });

                      return Object.entries(averagesPerActivity).map(([activity, average]) => (
                        <Typography key={activity} variant="body1">{`${activity}: ${average.toFixed(2)}`}</Typography>
                      ));
                    })()}

                    <Typography variant="h6">Average hours per week (Future)</Typography>
                    {/* Calculate the average hours per week for all upcoming sessions 
                    <Typography variant="body1">
                    {(() => {
                      const totalHours = trainingSessions.filter(session => !session.executed && new Date(session.date) > new Date()).reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                      const averageHoursPerWeek = totalHours / 3;
                      return averageHoursPerWeek.toFixed(2); // Display with 2 decimal places
                    })()}
                    </Typography>

                    <Typography variant="h6">Average hours per week per activity (Future)</Typography>
                      {/* Calculate the average hours per week for each activity 
                      {(() => {
                        const activities = new Set(trainingSessions.map(session => session.activity)); // Get unique activities
                        const averagesPerActivity: { [activity: string]: number } = {};

                        activities.forEach(activity => {
                          const totalHours = trainingSessions.filter(session => session.activity === activity && !session.executed && new Date(session.date) > new Date()).reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                          averagesPerActivity[activity] = totalHours / 3;
                        });

                        return Object.entries(averagesPerActivity).map(([activity, average]) => (
                          <Typography key={activity} variant="body1">{`${activity}: ${average.toFixed(2)}`}</Typography>
                        ));
                      })()}

                      <Typography variant="h6">Average hours per day (Future)</Typography>
                      {/* Calculate the average hours per day for all sessions 
                      <Typography variant="body1">
                      {(() => {
                        const totalHours = trainingSessions.filter(session => !session.executed && new Date(session.date) > new Date()).reduce((total, session) => total + ((session.durationInMinutes ? Number(session.durationInMinutes) : 0) / 60), 0);
                        const averageHoursPerDay = totalHours / (3 * 7); // three weeks, 7 days per week
                        return averageHoursPerDay.toFixed(2); // Display with 2 decimal places
                      })()}
                      </Typography>
                  </Box>
                </CardContent>
              </Card>
              
            </Grid> */}


