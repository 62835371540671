import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField, Box, Typography } from '@mui/material';
import { createQuestionnaire, getSubStatusByUserId } from '../api';
import jwt_decode from 'jwt-decode';
import jwtDecode from 'jwt-decode';

interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  // Include any other properties you expect to be in the token
}

const Questionnaire = () => {
  const [questionOne, setQuestionOne] = useState('');
  const [questionTwo, setQuestionTwo] = useState('');
  const [questionThree, setQuestionThree] = useState('');
  const [questionFour, setQuestionFour] = useState('');
  const [questionFive, setQuestionFive] = useState('');
  const [questionSix, setQuestionSix] = useState('');
  const [questionSeven, setQuestionSeven] = useState('');
  const [questionEight, setQuestionEight] = useState('');
  const [questionNine, setQuestionNine] = useState('');
  const [questionTen, setQuestionTen] = useState('');
  const [questionEleven, setQuestionEleven] = useState('');
  const [questionTwelve, setQuestionTwelve] = useState('');
  const [questionThirteen, setQuestionThirteen] = useState('');
  const [questionFourteen, setQuestionFourteen] = useState('');
  const [questionFifteen, setQuestionFifteen] = useState('');
  const [questionSixteen, setQuestionSixteen] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // Check if a valid token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {

      } else {
        // Token is expired
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
      if (decodedToken.activeSubscription === false) {
        navigate('/subscription', { replace: true });
      } 
    }

    
  }, []);

  const getsubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (token === null) {
        console.error("Token not found in local storage");
        return; // End the function if there's no token
      }

      const decodedToken = jwt_decode(token) as DecodedToken;
      const userId = decodedToken.id;

      const subStatus = await getSubStatusByUserId(userId);
      console.log(subStatus);
      if(subStatus.subscriptionStatus === true) {
        // navigate('/subscription', { replace: true });
        console.log('active')
      } else {
        navigate('/subscription', { replace: true });
        console.log('not active')
      }
    } catch (error:any) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    getsubscriptionStatus();
  }, []); 

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
        
    if (token === null) {
      console.error("Token not found in local storage");
      return; // End the function if there's no token
    }

    const decodedToken = jwt_decode(token) as DecodedToken;
    const userId = decodedToken.id;

    const questionnaireResponses = {
      userId: userId,
      timestamp: new Date(),
      questions: [
        { question: "Goal: ", answer: questionOne },
        { question: "Fitnesslevel in goal-activity: ", answer: questionSixteen },
        { question: "Status to goal: ", answer: questionTwo },
        { question: "Training volume last three months: ", answer: questionThree },
        { question: "KM per week running last three months: ", answer: questionFour },
        { question: "Current injuries: ", answer: questionFive },
        { question: "Safety considerations/other considerations: ", answer: questionSix },
        { question: "Hours per week in program: ", answer: questionSeven },
        { question: "Sessions per week program: ", answer: questionEight },
        { question: "Activities to use:", answer: questionNine },
        { question: "Equipment available", answer: questionTen },
        { question: "Mastery of squats: ", answer: questionEleven },
        { question: "Mastery of deadlift: ", answer: questionTwelve },
        { question: "Mastery of benchpress: ", answer: questionThirteen },
        { question: "Five pullups: ", answer: questionFourteen },
        { question: "Strength training session types: ", answer: questionFifteen },
      ]
    };

    const result = await createQuestionnaire(questionnaireResponses);
    if (result) {
      alert('Questionnaire added');
      // Reset the form
      setQuestionOne('');
      setQuestionTwo('');
      setQuestionThree('');
      setQuestionFour('');
      setQuestionFive('');
      setQuestionSix('');
      setQuestionSeven('');
      setQuestionEight('');
      setQuestionNine('');
      setQuestionTen('');
      setQuestionEleven('');
      setQuestionTwelve('');
      setQuestionThirteen('');
      setQuestionFourteen('');
      setQuestionFifteen('');
      setQuestionSixteen('');
    } else {
      alert('An error occurred');
    }
};


  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="background.default"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Questionnaire
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            State your goal as clearly as possible (f.ex: I want to run faster. Even better: I want to run 10km in 40 minutes or less)
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionOne} 
            onChange={(e) => setQuestionOne(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
           Where are you in regards to your goal?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionTwo} 
            onChange={(e) => setQuestionTwo(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            What is your fitnesslevel the activities used for training?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionSixteen} 
            onChange={(e) => setQuestionSixteen(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            What is your average training volume per week last three months? Answer ideally in hours per week and sessions per week, but either is fine as well.
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionThree} 
            onChange={(e) => setQuestionThree(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            How many km per week have you ran on average the last three months?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionFour} 
            onChange={(e) => setQuestionFour(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Do you have any ongoing injuries at the moment?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionFive} 
            onChange={(e) => setQuestionFive(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Is there anything I need to take into consideration when planning for you in regards to safety or other things?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionSix} 
            onChange={(e) => setQuestionSix(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            How many hours do you want to train per week?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionSeven} 
            onChange={(e) => setQuestionSeven(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            How many sessions do you want to train per week?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionEight} 
            onChange={(e) => setQuestionEight(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            What activities do you want to use in your training? 
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionNine} 
            onChange={(e) => setQuestionNine(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Describe the training equipment you have available and wish to use in your training:
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionTen} 
            onChange={(e) => setQuestionTen(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Can you master the squat exercise on your own?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionEleven} 
            onChange={(e) => setQuestionEleven(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Can you master the deadlift exercise on your own?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionTwelve} 
            onChange={(e) => setQuestionTwelve(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Can you master the bench press exercise on your own?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionThirteen} 
            onChange={(e) => setQuestionThirteen(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Can you do at least five pull-ups?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionFourteen} 
            onChange={(e) => setQuestionFourteen(e.target.value)}
          />
        </Box>

        <Box m={2}>
          <Typography variant="overline" gutterBottom>
            Do you want to use machines, bodyweight exercises, or free weights (bar, weight plates, and dumbbells) when doing strength training?
          </Typography>
          <TextField 
            label="Your Answer" 
            variant="outlined" 
            multiline 
            rows={4} 
            fullWidth 
            value={questionFifteen} 
            onChange={(e) => setQuestionFifteen(e.target.value)}
          />
        </Box>
        <Box m={2}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Questionnaire;
