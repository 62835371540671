import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import jwtDecode from 'jwt-decode';

interface NavBarProps {
  isLoggedIn: boolean;
  setIsLoggedIn: (loggedIn: boolean) => void;
}

const NavBar: React.FC<NavBarProps> = ({ isLoggedIn, setIsLoggedIn }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [adminOrCoach, setAdminOrCoach] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          AP-Performance Coaching
        </Typography>
        <Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {isLoggedIn ? [
               <>
               <MenuItem component={Link} to="/" onClick={handleClose} key="UserDashboard">
                 Dashboard
               </MenuItem>
               <MenuItem component={Link} to="/questionnaire" onClick={handleClose} key="Questionnaire">
                 Questionnaire
               </MenuItem>
               <MenuItem component={Link} to="/subscription" onClick={handleClose} key="Subscription">
                 Subscription
               </MenuItem>
               {/* <MenuItem component={Link} to="/stats" onClick={handleClose} key="Stats">
                 StatsPage
               </MenuItem> */}
               <MenuItem component={Link} to="/invitation" onClick={handleClose} key="Invitations">
                 Invitations
               </MenuItem>
               <MenuItem component={Link} to="/settings" onClick={handleClose} key="Settings">
                 Settings
               </MenuItem>
               <MenuItem onClick={handleLogout} key="Logout">
                 Logout
               </MenuItem>
             </>
            ] : [
              <MenuItem component={Link} to="/login" onClick={handleClose} key="LoginPage">
                Login
              </MenuItem>,
              <MenuItem component={Link} to="/registration" onClick={handleClose} key="RegistrationPage">
                Registration
              </MenuItem>,
            ]}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
