import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Button, Box, Typography, Select, MenuItem, InputLabel, FormControl, Container, FormLabel, RadioGroup, Radio, FormControlLabel  } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { getUserById, createStripeCheckoutSession } from '../api';


interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  // Include any other properties you expect to be in the token
}

const PB_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
console.log("full", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// console.log("not full", REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(PB_KEY!);

const SubscriptionPage = () => { 
  const [userId, setUserId] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [subscriptionType, setSubscriptionType] = useState('coaching');

  const navigate = useNavigate();

  useEffect(() => {
    // Check if a valid token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
      setUserId(decodedToken.id);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {

      } else {
        // Token is expired
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
    }

    
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const userInfoResponse = await getUserById(userId);
      console.log(userInfoResponse);

      if (userInfoResponse) {
        try {
          console.log(userInfoResponse.email, userInfoResponse.firstName, userInfoResponse.lastName, subscriptionType);
          const session = await createStripeCheckoutSession(userInfoResponse.email, userInfoResponse.firstName, userInfoResponse.lastName, subscriptionType);
          console.log(PB_KEY);
          const stripe = await (window as any).Stripe(PB_KEY!);
          await stripe.redirectToCheckout({ sessionId: session.id });
        } catch (error) {
          console.error('Error creating Stripe checkout session:', error);
        }
      } else {
        console.error('Error getting user information:', userInfoResponse.message);
      }
    } catch (error: any) {
      console.error('Error registering user:', error.message);
    } finally {
      alert('Subscription successful!');
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100vh"
        bgcolor="background.default"
        p={4} // padding to match other page
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Subscription
        </Typography>
        <Elements stripe={stripePromise}>
        <form>
          <Box mb={2} mt={2}> {/* Added top margin */}
          <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend">Subscription Type</FormLabel>
              <RadioGroup value={subscriptionType} onChange={(e) => setSubscriptionType(e.target.value)}>
                <FormControlLabel value="coaching" control={<Radio />} label="AP-Performance Coaching 999,-" />
              </RadioGroup>
              {/* <RadioGroup value={subscriptionType} onChange={(e) => setSubscriptionType(e.target.value)}>
                <FormControlLabel value="coaching" control={<Radio />} label="AP-Performance Coaching 799,-" />
              </RadioGroup> */}
            </FormControl>
          </Box>
          <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
            Subscribe
          </Button>
        </form>
        </Elements>
        {/* <Box style={{ width: '100%', padding: '16px', marginBottom: '16px', marginTop: '20px' }}>
          a box with active subscription if one has an active subscription so that it can be cancelled
        </Box> */}
      </Box>
    </Container>
  );
};

export default SubscriptionPage;
