import React, {useState, useEffect} from 'react';
import { Button, Box, Typography, Select, MenuItem, InputLabel, FormControl, Paper, Container, TextField, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getSubStatusByUserId, indentifyUsersInNeedOfFeedback, saveTrainingPlan, sendInvitation, getConnectedAthletes, getQuestionnairesByUserId, createTrainingPlan, getDailyMarkersByUserIdAndDate, getUserTrainingSessionsLastFourWeeks, createTrainingSession, createTrainingSessions } from '../api';
import { TrainingSessionData, TrainingSessions } from './../models/trainingSessionsModelFrontend';
import { DailyWellnessAndTrainingByDate } from './../models/interfaces';
import { Questionnaire } from './../models/questionnaireModelFrontEnd';
import jwt_decode from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import UserDashOnAdmin from '../components/UserDashOnAdmin';
import { start } from 'repl';
import { DailyMarker } from '../models/dailyMarkersModelFronted';
import AddTrainingPlansPage from './AddTrainingPlansPage';
import CircularProgress from '@mui/material/CircularProgress';
import { set } from 'mongoose';


interface Athlete {
  userId: string;
  firstName: string;
  lastName: string;
}

interface MoodData {
  timestamp: Date;
  mood: string;
}

interface TrainingSession {
  date: string;
  activity: string;
  sessionTitle: string;
  intensityRatingOfPerceivedExertion: number;
  comment?: string;
  executed: boolean;
}

interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  // Include any other properties you expect to be in the token
}

const AdminDashPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [connectedAthletes, setConnectedAthletes] = useState<Athlete[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null);
  const [gptRoleContent, setGptRoleContent] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showPlanning, setShowPlanning] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [goalForPeriod, setGoalForPeriod] = useState<string>('');
  const [planContext, setPlanContext] = useState<string>('');
  const [qestionnaireSummary, setQestionnaireSummary] = useState<string>('');
  const [sessionsPerWeek, setSessionsPerWeek] = useState<string>('');
  const [showAnalysisBox, setShowAnalysisBox] = useState(false);
  const [analysisStartDate, setAnalysisStartDate] = useState('');
  const [analysisEndDate, setAnalysisEndDate] = useState('');
  const [analysisResult, setAnalysisResult] = useState('');
  const [isMakingPlan, setIsMakingPlan] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isShortAnalyzing, setIsShortAnalyzing] = useState(false);
  const [identifyingUsers, setIdentifyingUsers] = useState(false);
  const [usersInNeedOfFeedback, setUsersInNeedOfFeedback] = useState<string>('');
  const [jsonInput, setJsonInput] = useState<string>('');
  const [trainingSessions, setTrainingSessions] = useState<TrainingSessionData[]>([]);
  const [gridDates, setGridDates] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const INTENSITY_ZONE_COLORS = ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'];

  useEffect(() => {
    // Check if a valid token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken:DecodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
  
      if (decodedToken.exp > currentTime) {

      } else {
        // Token is expired
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
      if (decodedToken.activeSubscription === false) {
        navigate('/subscription', { replace: true });
      } 
    }

    
  }, []);

  const getsubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (token === null) {
        console.error("Token not found in local storage");
        return; // End the function if there's no token
      }

      const decodedToken = jwt_decode(token) as DecodedToken;
      const userId = decodedToken.id;

      const subStatus = await getSubStatusByUserId(userId);
      console.log(subStatus);
      if(subStatus.subscriptionStatus === true) {
        // navigate('/subscription', { replace: true });
        console.log('active')
      } else {
        navigate('/subscription', { replace: true });
        console.log('not active')
      }
    } catch (error:any) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    getsubscriptionStatus();
  }, []); 

  const handleStartDateChange = (e: any) => {
    const newStartDate = new Date(e.target.value);
    setStartDate(newStartDate.toISOString().split('T')[0]); // set the start date

    // Calculate end date (4 weeks later)
    const newEndDate = new Date(newStartDate);
    newEndDate.setDate(newStartDate.getDate() + 6); // Add 28 days
    setEndDate(newEndDate.toISOString().split('T')[0]); // set the end date
  };

  useEffect(() => {
    const fetchConnectedAthletes = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (token === null) {
          console.error("Token not found in local storage");
          return; // End the function if there's no token
        }
  
        const decodedToken = jwt_decode(token) as DecodedToken;
        const userId = decodedToken.id;
  
        const athletes = await getConnectedAthletes(userId);
        console.log(athletes);
        setConnectedAthletes(athletes);
      } catch (error:any) {
        console.error(error.message);
      }
    };
  
    fetchConnectedAthletes();
  }, []);

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      const result = await getQuestionnairesByUserId(selectedUser);
      setQuestionnaire(result);
    };
  
    if (selectedUser) {
      fetchQuestionnaire();
    }
  }, [selectedUser]);

  const handleAddPlansButtonClick = () => {
    if(showPlanning) {
      setShowPlanning(false)
    } else {
      setShowPlanning(true)
    }
  };

  const handleQuSummaryButtonClick = () => {
    navigate('/admin-dashboard/addSummary');
  };

  const handleIdUsersInNeedOfFeedBackClick = async () => {
    setIdentifyingUsers(true);
    const today = new Date().toISOString().split('T')[0];
    const aWeekAgo = new Date();
    aWeekAgo.setDate(aWeekAgo.getDate() - 7);
    const aWeekAgoString = aWeekAgo.toISOString().split('T')[0];
  
    const athletesData = [];
  
    for (const athlete of connectedAthletes) {
      try {
        const trainingSessions = await getUserTrainingSessionsLastFourWeeks(athlete.userId, aWeekAgoString, today);
        const wellness = await getDailyMarkersByUserIdAndDate(athlete.userId, aWeekAgoString, today);
  
        const datesInRange = getAllDatesInRange(aWeekAgoString, today);
        const dailySummaries = datesInRange.map(date => {
          const dayTrainingSessions = trainingSessions.filter((session: TrainingSession) => session.date.startsWith(date));
          const dayMoodData = wellness.filter((mood: MoodData) => mood.timestamp.toISOString().split('T')[0] === date);
        
          return summarizeDay(date, dayTrainingSessions, dayMoodData);
        });
  
        athletesData.push({
          userId: athlete.firstName,
          dailySummaries: dailySummaries
        });
      } catch (error) {
        console.error(`Error fetching data for athlete ${athlete.userId}:`, error);
      }
    }
  
    console.log(athletesData);

    const roleForGPTTemp: string = "You are a helpful dataanalyst of physical performance-training";
    
    const message: string = 
    `I am a physical fitness coach. Here is a summary of all my athletes training the last week: ` +
    `${JSON.stringify(athletesData)}. ` +
    `Identify the athletes that need feedback based on the training and mood data. `
    ;

// WHEN I GET A LOT OF CLIENT: use this string instead of the last two sentences.:
// `With the shortest possible response; identify the athletes that need feedback for me based on the training and mood data. `

    const response = await indentifyUsersInNeedOfFeedback(roleForGPTTemp, message);
    console.log(response);

    
    const responseString: string = response.choices[0].message.content; 
    console.log(responseString);
    setUsersInNeedOfFeedback(responseString);
    setIdentifyingUsers(false);
  };

  const getAllDatesInRange = (startDateString:string, endDateString:string) => {
    const dates = [];
    let currentDate = new Date(startDateString);
  
    while (currentDate <= new Date(endDateString)) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dates;
  };
  

  const summarizeDay = (
    date: string, 
    dayTrainingSessions: TrainingSession[], 
    dayMoodData: MoodData[]
  ) => {
    let summary = `Date: ${date}, Training: `;
  
    if (dayTrainingSessions.length > 0) {
      summary += dayTrainingSessions.map(session => `${session.activity} - ${session.sessionTitle} - Intensity: ${session.intensityRatingOfPerceivedExertion}/10 - Comment: ${session.comment || "No"} - Executed: ${session.executed || "No"}`).join(', ');
    } else {
      summary += 'No training sessions';
    }
  
    summary += ', Mood: ';
  
    if (dayMoodData && dayMoodData.length > 0) {
      summary += dayMoodData.map(mood => mood.mood).join(', ');
    } else {
      summary += 'No mood data';
    }
  
    return summary;
  };
    

  const handleMakePlan = async () => {
    if (!startDate || !endDate || !selectedUser) {
      alert("Start date, end date, and user selection are required.");
      return; // Exit if essential fields are missing
    }
    setIsMakingPlan(true);
    // Calculate date for four weeks ago from startDate
    const twoWeeksAgo = new Date(startDate);
    if (isNaN(twoWeeksAgo.getTime())) {
      console.error("Invalid startDate format.");
      return; // Exit if startDate is not a valid date
    }

    const oneWeekBeforeToday = new Date();
    oneWeekBeforeToday.setDate(oneWeekBeforeToday.getDate() - 7);
    const oneWeekBeforeTodayString = oneWeekBeforeToday.toISOString().split('T')[0];

    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    const twoWeeksAgoString = twoWeeksAgo.toISOString().split('T')[0];

    const today = new Date().toISOString().split('T')[0];
    let trainingSessions, wellness, trainingAndMoodString;
    if (startDate === today) {
      try {
        trainingSessions = await getUserTrainingSessionsLastFourWeeks(selectedUser, twoWeeksAgoString, startDate);
        wellness = await getDailyMarkersByUserIdAndDate(selectedUser, twoWeeksAgoString, startDate);
      } catch (error) {
        console.error("Error fetching training sessions or wellness data:", error);
        return; // Exit if there's an error fetching data
      }
  
      const dailyWellnessAndTrainingByDate = createDailyWellnessAndTrainingArray(trainingSessions, wellness);
  
      trainingAndMoodString = `The last 14 days of training and mood: ${JSON.stringify(dailyWellnessAndTrainingByDate)}. "moodDetails" is only provided if "mood" is "'lousy' | 'poor'". If mood is "", the user probably forgot to register it. `
  
    } if (startDate > today) {
      let trainingSessionsLastWeek, wellnessLastWeek, trainingSessionsUpToStartDate;

      // Fetch training sessions and wellness from one week before today
      try {
          trainingSessionsLastWeek = await getUserTrainingSessionsLastFourWeeks(selectedUser, oneWeekBeforeTodayString, today);
          wellnessLastWeek = await getDailyMarkersByUserIdAndDate(selectedUser, oneWeekBeforeTodayString, today);
      } catch (error) {
          console.error("Error fetching training sessions or wellness data for the last week:", error);
          return;
      }

      // Fetch just the training sessions from today until the day before startDate
      const dayBeforeStartDate = new Date(startDate);
      dayBeforeStartDate.setDate(dayBeforeStartDate.getDate() - 1);
      const dayBeforeStartDateString = dayBeforeStartDate.toISOString().split('T')[0];
      const dayBeforeToday = new Date(today);
      dayBeforeToday.setDate(dayBeforeToday.getDate() - 1);
      const dayBeforeTodayDateString = dayBeforeToday.toISOString().split('T')[0];

      try {
          trainingSessionsUpToStartDate = await getUserTrainingSessionsLastFourWeeks(selectedUser, today, dayBeforeStartDateString);
      } catch (error) {
          console.error("Error fetching training sessions data up to startDate:", error);
          return;
      }

      // Combine and format the data
      const dailyWellnessAndTrainingByDate = createDailyWellnessAndTrainingArray(trainingSessionsLastWeek, wellnessLastWeek);
      const trainingSessionsUpToStartDateString = JSON.stringify(trainingSessionsUpToStartDate);

      trainingAndMoodString = `Here is the training and mood between ${oneWeekBeforeTodayString} and ${dayBeforeTodayDateString}: ${JSON.stringify(dailyWellnessAndTrainingByDate)}. Here is the current trainingprogram from ${today} until ${dayBeforeStartDateString}: ${trainingSessionsUpToStartDateString}.`;
  }
    
    
    // Validate non-null values for message construction
    if (!gptRoleContent || !goalForPeriod) {
      console.error("One or more required fields for message construction are missing.");
      return; // Exit if any required field for message construction is missing
    }

    const roleForGPTTemp: string = "You are a helpful assistant designed to output JSON.";
    
    const message: string = 
    `You are a helpful ${gptRoleContent}. ` +
    `Make a trainingprogram FROM ${startDate} TO ${endDate} with ${sessionsPerWeek} sessions per week for this person: ${questionnaire?.summary} .` + 
    `The goal for this specific trainingperiod is ${goalForPeriod}. ` +
    `Analyze the the previous training in this JSON to get good context:  ${trainingAndMoodString}. ` +
    `Additional context: ${planContext}. ` +
    `PROVIDE A DETAILED description of each trainingSession in the JSON.sessionDescription so the athlete know exactly how to train (f.ex. the number and duration of intervals etc. ). ` +
    `Return the trainingprogram in a valid JSON format from this model:
     [{
        date: YYYY-MM-DD,
        plannedIntensity: 'low'| 'medium' | 'high' | 'exhaustive',
        activity: string,
        sessionTitle: string,
        sessionDescription: string,
        durationInMinutes: number,
      }]. ` + 
      `Think step-by-step and take your time to make the best program possible.`
      ;

      const trainingPlan = await createTrainingPlan(selectedUser, roleForGPTTemp, message);

      const trainingPlans: string = trainingPlan.choices[0].message.content;
      console.log(trainingPlans);
      const jsonStartIdentifier = "[";
      const jsonEndIdentifier = "]";

      // Find the start and end index of the JSON content
      const startIndex = trainingPlans.indexOf(jsonStartIdentifier);
      const endIndex = trainingPlans.lastIndexOf(jsonEndIdentifier) + 1

      if (startIndex !== -1 && endIndex !== -1) {
        try {
          const jsonString = trainingPlans.substring(startIndex, endIndex).trim();

          console.log("Extracted JSON String:", jsonString);

          const trainingPlanArray = createTrainingPlanArray(jsonString, selectedUser);
          console.log(trainingPlanArray); 
          await saveTrainingPlan(trainingPlanArray);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return; // Exit if there's an error parsing the JSON
        }
        
    } else {
        console.error("JSON content not found in the string");
    }
    setIsMakingPlan(false);
  };

  const createDailyWellnessAndTrainingArray = (trainingSessions: TrainingSessionData[], wellness:DailyMarker[]) => {
    const dailyWellnessAndTrainingByDate: DailyWellnessAndTrainingByDate[] = [];
  
    // Create a map for quick lookup of wellness data by date
    const wellnessByDate = new Map(wellness.map(w => [w.timestamp.toISOString().split('T')[0], w]));
  
    // Iterate over training sessions and match them with wellness data
    trainingSessions.forEach(session => {
      const date = session.date.split('T')[0];
      const wellnessData = wellnessByDate.get(date);
  
      // Find if there is already an entry for this date
      let dailyEntry = dailyWellnessAndTrainingByDate.find(entry => entry.date === date);
      if (!dailyEntry) {
        // If no entry for this date, create a new one
        dailyEntry = {
          date: date,
          mood: wellnessData ? wellnessData.mood : '',
          moodDetails: wellnessData ? [{
            recoveryStatus: wellnessData.recoveryStatus,
            restedStatus: wellnessData.restedStatus,
            motivationStatus: wellnessData.motivationStatus,
            stressStatus: wellnessData.stressStatus,
            sleepQualityStatus: wellnessData.sleepQualityStatus,
            sleepQuantityStatus: wellnessData.sleepQuantityStatus,
            injuryStatus: wellnessData.injuryStatus,
          }] : [],
          training: []
        };
        dailyWellnessAndTrainingByDate.push(dailyEntry);
      }
  
      // Add training session to the daily entry
      dailyEntry.training.push({
        activity: session.activity,
        sessionTitle: session.sessionTitle,
        sessionDescription: session.sessionDescription,
        comment: session.comment,
        intensityRatingOfPerceivedExertion: session.intensityRatingOfPerceivedExertion,
        durationInMinutes: session.durationInMinutes,
        distanceInKm: session.distanceInKm,
        strengthTraining: session.strengthTraining,
        feelingDuring: session.feelingDuring,
        executed: session.executed || false,
      });
    });
  
    return dailyWellnessAndTrainingByDate;
  };

  const createTrainingPlanArray = (jsonResponse: string, selectedUser: string) => {
    // Parse the JSON string to an array
    const parsedResponse: TrainingSessionData[] = JSON.parse(jsonResponse);
  
    // Transform each object to fit the TrainingSessionData model
    return parsedResponse.map(session => ({
      userId: selectedUser,
      date: session.date,
      plannedIntensity: session.plannedIntensity,
      activity: session.activity,
      sessionTitle: session.sessionTitle,
      sessionDescription: session.sessionDescription,
      comment: '', // Placeholder value
      intensityRatingOfPerceivedExertion: session.intensityRatingOfPerceivedExertion || 0, // Placeholder value if not provided
      durationInMinutes: session.durationInMinutes,
      distanceInKm: session.distanceInKm || 0, // Placeholder value if not provided
      intensityZones: {}, // Placeholder value
      strengthTraining: [], // Placeholder value or logic to determine if applicable
      feelingDuring: '', // Placeholder value
      executed: false // Placeholder value
    }));
  };

  const handleAnalyzeTrainingExecution = async () => {
    console.log({ analysisStartDate, analysisEndDate, selectedUser });

    // Ensure start and end dates are set
    if (!analysisStartDate || !analysisEndDate || !selectedUser) {
      alert("Start date, end date, and user selection are required for short analysis.");
      return;
    }
    setIsAnalyzing(true);
    let trainingSessions, wellness, trainingAndMoodString;
    try {
      trainingSessions = await getUserTrainingSessionsLastFourWeeks(selectedUser, analysisStartDate, analysisEndDate);
      wellness = await getDailyMarkersByUserIdAndDate(selectedUser, analysisStartDate, analysisEndDate);
    } catch (error) {
      console.error("Error fetching training sessions or wellness data:", error);
      return; // Exit if there's an error fetching data
    }

    const dailyWellnessAndTrainingByDate = createDailyWellnessAndTrainingArray(trainingSessions, wellness);

    trainingAndMoodString = `Training and wellness/mood between ${analysisStartDate} and ${analysisEndDate}:  ${JSON.stringify(dailyWellnessAndTrainingByDate)}. "moodDetails" is only provided if "mood" is "'lousy' | 'poor'". If mood is "", the user probably forgot to register it. `

  
    if (!goalForPeriod) {
      alert("Goal must be provided");
      return;
    }

    const roleForGPTTemp: string = `You are a helpful fitness and physical performance analyst. `;
    
    const message: string = 
      `Analyze the user's training execution in this JSON: ${trainingAndMoodString}. ` +
      `Provide important feedback on the training process (especially the balance between executed and non-executed-session) with regards to the user's goal: ${goalForPeriod}.`;

      console.log(roleForGPTTemp);
      console.log(message);

      const analysis = await createTrainingPlan(selectedUser, roleForGPTTemp, message);

      const analysisString: string = analysis.choices[0].message.content;
      console.log(analysisString);
      setAnalysisResult(analysisString);
      setIsAnalyzing(false);
  };

  const handleShortAnalyzeTrainingExecution = async () => {
    console.log({ analysisStartDate, analysisEndDate, selectedUser });

    // Ensure start and end dates are set
    if (!analysisStartDate || !analysisEndDate || !selectedUser) {
      alert("Start date, end date, and user selection are required for short analysis.");
      return;
    }
    setIsShortAnalyzing(true);

    let trainingSessions, wellness, trainingAndMoodString;
    try {
      trainingSessions = await getUserTrainingSessionsLastFourWeeks(selectedUser, analysisStartDate, analysisEndDate);
      wellness = await getDailyMarkersByUserIdAndDate(selectedUser, analysisStartDate, analysisEndDate);
    } catch (error) {
      console.error("Error fetching training sessions or wellness data:", error);
      return; // Exit if there's an error fetching data
    }

    const dailyWellnessAndTrainingByDate = createDailyWellnessAndTrainingArray(trainingSessions, wellness);

    trainingAndMoodString = `Training and wellness/mood between ${analysisStartDate} and ${analysisEndDate}:  ${JSON.stringify(dailyWellnessAndTrainingByDate)}. "moodDetails" is only provided if "mood" is "'lousy' | 'poor'". If mood is "", the user probably forgot to register it. `

  
    if (!goalForPeriod) {
      alert("Goal is required.");
      return;
    }

    const roleForGPTTemp: string = `You are a helpful fitness and physical performance analyst. `;
    
    const message: string = 
      `Provide the shortest possible analysis of the users trainingexecution with regards to the users end goal: ${goalForPeriod}.  ` +
      `Training and mood: ${trainingAndMoodString}.`;

      console.log(roleForGPTTemp);
      console.log(message);

      const analysis = await createTrainingPlan(selectedUser, roleForGPTTemp, message);

      const analysisString: string = analysis.choices[0].message.content;
      console.log(analysisString);
      setAnalysisResult(analysisString); // Update the state with the analysis result
      setIsShortAnalyzing(false);
  };
  
  
  const handleSendInvitation = async () => {
    try {
      const response = await sendInvitation(email);
      console.log(response);
      if (response.ok) { // response.ok is true if status code is 200-299
        alert(`Invitation sent to ${email}`);
      } else {
        const errorMessage = await response.json(); // Assuming server responds with JSON
        alert(`Error: ${errorMessage.message}`);
      }
    } catch (error:any) {
      console.error(error.message);
      alert('Network error. Please try again later.');
    }
  };  

  const formatFeedback = (feedback: string) => {
    if (!feedback || typeof feedback !== 'string') {
      console.error('Invalid feedback:', feedback);
      return 'Invalid feedback data';
    }
  
    const sections = feedback.split("\n\n");
    return sections.map((section, index) => {
      if (section.startsWith('1.') || section.startsWith('2.')) {
        const points = section.split("\n").filter(line => line);
        return (
          <div key={index}>
            <strong>{points.shift()}</strong> {/* First line is the athlete's name */}
            <ul>
              {points.map((point, idx) => <li key={idx}>{point}</li>)}
            </ul>
          </div>
        );
      } else {
        return <p key={index}>{section}</p>;
      }
    });
  };

  return (
    <Container maxWidth={false}>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="background.default"
      p={4} // Increased padding to match other page
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={2} mt={2}> {/* Added top margin */}
            <FormControl fullWidth variant="outlined">
                <InputLabel id="user-label">Choose User</InputLabel>
                <Select
                  labelId="user-label"
                  label="Choose User"
                  value={selectedUser}
                  onChange={(event) => setSelectedUser(event.target.value as string)}
                >
                  {Array.isArray(connectedAthletes) && connectedAthletes.map((athlete) => (
                    <MenuItem value={athlete.userId}>{athlete.firstName} {athlete.lastName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Box>
              <UserDashOnAdmin userId={selectedUser}></UserDashOnAdmin>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <div>
          {questionnaire ? (
            <Paper elevation={3} style={{ padding: '16px' }}>
              <h2>Latest questionnairesummary on user</h2>
              <p>
                <strong>Summary:</strong> {questionnaire.summary && questionnaire.summary}
              </p>
            </Paper>
          ) : (
            <Paper elevation={3} style={{padding: '16px' }}>
              <Typography variant="h6">No questionnairesummary available</Typography>
            </Paper>
          )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleAddPlansButtonClick} fullWidth style={{ marginTop: '20px' }}>
            Add Training Plan to User
          </Button>
          {showPlanning && 
              <>  
                <TextField
                  id="roleForGPT"
                  label="Role for GPT"
                  multiline
                  fullWidth
                  variant="outlined"
                  value={gptRoleContent}
                  onChange={(e) => setGptRoleContent(e.target.value)}
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  id="startDate"
                  // label="Start Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={startDate}
                  onChange={handleStartDateChange}
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  id="endDate"
                  // label="End Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={endDate}
                  style={{ marginTop: '20px' }}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="goalForPeriod"
                  label="Goal for Period"
                  multiline
                  fullWidth
                  variant="outlined"
                  value={goalForPeriod}
                  onChange={(e) => setGoalForPeriod(e.target.value)}
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  id="sessionsPerWeek"
                  label="Sessions per week"
                  multiline
                  fullWidth
                  variant="outlined"
                  value={sessionsPerWeek}
                  onChange={(e) => setSessionsPerWeek(e.target.value)}
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  id="context"
                  label="Context"
                  multiline
                  fullWidth
                  variant="outlined"
                  value={planContext}
                  onChange={(e) => setPlanContext(e.target.value)}
                  style={{ marginTop: '20px' }}
                />
                <Button
                  variant="contained"
                  onClick={handleMakePlan}
                  style={{ marginTop: '20px' }}
                >
                  {isMakingPlan ? <CircularProgress size={24} color="secondary" /> : 'Make Plan'}
                </Button>
              </>
            }
          <Button variant="contained" color="primary" onClick={() => setShowAnalysisBox(!showAnalysisBox)} fullWidth style={{ marginTop: '20px' }}>
            Analyze Training Execution
          </Button>
          {showAnalysisBox && (
            <Box>
              <Typography variant="h6">Select Date Range</Typography>
              <TextField
                id="analysisStartDate"
                type="date"
                variant="outlined"
                fullWidth
                value={analysisStartDate}
                onChange={(e) => setAnalysisStartDate(e.target.value)}
                style={{ marginTop: '20px' }}
              />
              <TextField
                id="analysisEndDate"
                type="date"
                variant="outlined"
                fullWidth
                value={analysisEndDate}
                onChange={(e) => setAnalysisEndDate(e.target.value)}
                style={{ marginTop: '20px' }}
              />
              <TextField
                  id="goalForPeriod"
                  label="Goal for Period"
                  multiline
                  fullWidth
                  variant="outlined"
                  value={goalForPeriod}
                  onChange={(e) => setGoalForPeriod(e.target.value)}
                  style={{ marginTop: '20px' }}
                />
              <Button
                variant="contained"
                onClick={handleAnalyzeTrainingExecution}
                style={{ marginTop: '20px', marginRight: '20px' }}
              >
                {isAnalyzing ? <CircularProgress size={24} color="secondary"  /> : 'Comprehensive Analysis'}
              </Button>
              <Button
                variant="contained"
                onClick={handleShortAnalyzeTrainingExecution}
                style={{ marginTop: '20px' }}
              >
                {isShortAnalyzing ? <CircularProgress size={24} color="secondary"  /> : 'Short Analysis'}
              </Button>
            </Box>
            
          )}
          {analysisResult && (
            <Paper style={{ padding: '16px', marginTop: '20px' }}>
              <Typography variant="h6">Analysis Result:</Typography>
              <Typography variant="body1">{analysisResult}</Typography>
            </Paper>
          )}
          <Button variant="contained" color="primary" onClick={handleQuSummaryButtonClick} fullWidth style={{ marginTop: '20px' }}>
            Add questionnaire summary to user
          </Button>
          <Button variant="contained" color="primary" onClick={handleIdUsersInNeedOfFeedBackClick} fullWidth style={{ marginTop: '20px' }}>
            {identifyingUsers ? <CircularProgress size={24} color="secondary"  /> : 'Identify users in need of feedback'}
          </Button>
          {usersInNeedOfFeedback && (
            <Paper style={{ padding: '16px', marginTop: '20px' }}>
              <Typography variant="h6">Users in need of feedback:</Typography>
              <div>{formatFeedback(usersInNeedOfFeedback)}</div>
            </Paper>
          )}
          <Typography variant="h6" style={{ marginTop: '20px' }}>Invite user to connect to you</Typography>
          <TextField
              label="User Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={handleSendInvitation}>
            Send Invitation
          </Button>
        </Grid>
      </Grid>
    </Box>
    <Typography variant="body2" align="left" color="textSecondary">
      Copyright AP-Performance Solutions AS 2023
    </Typography>
  </Container>
  );
};

export default AdminDashPage;
