// StatsWeeklyAdmindashComp.tsx
import React from 'react';
import { Box, Typography, Tooltip  } from '@mui/material';
import { DailyMarker } from '../models/dailyMarkersModelFronted';
import { TrainingSessions } from '../models/trainingSessionsModelFrontend';

interface StatsWeeklyAdmindashCompProps {
    dailyMarkers: DailyMarker[];
    trainingSessions: TrainingSessions[];
    week: Date; // Now this is a single Date object
  }

  type IntensityZones = {
    one: number;
    two: number;
    three: number;
    four: number;
    five: number;
  };

  const getWeekNumber = (date: Date) => {
    // Copy date so don't modify original
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
    // Return array of year and week number
    return weekNo;
  };
  
  

  const StatsWeeklyAdmindashComp: React.FC<StatsWeeklyAdmindashCompProps> = ({ dailyMarkers, trainingSessions, week }) => {

      // Helper function to calculate median
  const calculateMedian = (numbers: number[]) => {
    const sortedNumbers = numbers.sort((a, b) => a - b);
    const mid = Math.floor(sortedNumbers.length / 2);
    return sortedNumbers.length % 2 !== 0 ? sortedNumbers[mid] : (sortedNumbers[mid - 1] + sortedNumbers[mid]) / 2;
  };

  const weekNumber = getWeekNumber(week);

  // Function to convert mood to a numeric scale for median calculation
  const moodToNumeric = (mood: string) => {
    const moods: { [key: string]: number } = { 'lousy': 1, 'poor': 2, 'good': 3, 'great': 4, '': 0 };
    return moods[mood] as number;
};

const feelingToNumeric = (feeling:string) => {
    const feelings: { [key: string]: number } = { 'lousy': 1, 'poor': 2, 'good': 3, 'great': 4, '': 0 };
    return feelings[feeling] || 0;
  };
  
  // Convert a numeric value back to the corresponding feeling string
  const numericToFeeling = (num:number) => {
    const feelings: { [key: number]: string } = { 1: 'lousy', 2: 'poor', 3: 'good', 4: 'great', 0: '' };
    return feelings[num] || '';
  };

  const calculateStatistics = (week: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of the day
  
    const startOfWeek = new Date(week);
    startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() === 0 ? 6 : startOfWeek.getDay() - 1));
    startOfWeek.setHours(0, 0, 0, 0);
  
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
  
    const weekMarkers = dailyMarkers.filter(marker => {
      const markerDate = new Date(marker.timestamp);
      return markerDate >= startOfWeek && markerDate <= endOfWeek;
    });
  
    const weekSessions = trainingSessions.filter(session => {
      const sessionDate = new Date(session.date);
      return (sessionDate >= startOfWeek && sessionDate <= endOfWeek) && 
             (session.executed === true || sessionDate > today);
    });
  
    const feelingValues = weekSessions
      .map(session => feelingToNumeric(session.feelingDuring))
      .filter(val => val !== 0);
  
    const medianFeelingNumeric = calculateMedian(feelingValues);
    const meanFeeling = feelingValues.reduce((acc, feeling) => acc + feeling, 0) / (feelingValues.length || 1);
  
    const moodValues = weekMarkers
      .map(marker => moodToNumeric(marker.mood))
      .filter(val => val !== 0);
  
    const medianMood = calculateMedian(moodValues);
    const totalMood = moodValues.reduce((acc, mood) => acc + mood, 0);
    const meanMood = moodValues.length ? totalMood / moodValues.length : 0;
  
    const validRPEs = weekSessions
      .map(session => session.intensityRatingOfPerceivedExertion)
      .filter(val => val != null);
  
    const medianRPE = calculateMedian(validRPEs);
    const totalRPE = validRPEs.reduce((acc, rpe) => acc + rpe, 0);
    const meanRPE = validRPEs.length ? totalRPE / validRPEs.length : 0;
  
    const medianFeeling = numericToFeeling(medianFeelingNumeric);
    const sessionCount = weekSessions.length;
  
    const totalDuration = weekSessions.reduce((acc, session) => 
      acc + (session.durationInMinutes || 0), 0);
  
    const totalTrainingLoad = weekSessions.reduce((acc, session) => 
      acc + ((session.durationInMinutes || 0) * (session.intensityRatingOfPerceivedExertion || 0)), 0);
  
    const activityDurations: { [key: string]: { duration: number; distance: number } } = {};
    weekSessions.forEach(session => {
      const activity = session.activity;
      if (!activityDurations[activity]) {
        activityDurations[activity] = { duration: 0, distance: 0 };
      }
      activityDurations[activity].duration += session.durationInMinutes || 0;
      activityDurations[activity].distance += session.distanceInKm || 0;
    });
  
    const intensityZoneDurations: IntensityZones = { one: 0, two: 0, three: 0, four: 0, five: 0 };
    weekSessions.forEach(session => {
      if (session.intensityZones) {
        Object.keys(session.intensityZones as IntensityZones).forEach(zone => {
          const zoneKey = zone as keyof IntensityZones;
          intensityZoneDurations[zoneKey] += session.intensityZones[zoneKey] || 0;
        });
      }
    });
  
    const totalIntensityDuration = Object.values(intensityZoneDurations).reduce((a, b) => a + b, 0);
    const intensityZonePercents = Object.fromEntries(
      Object.entries(intensityZoneDurations).map(([zone, duration]) => [zone, totalIntensityDuration ? (duration / totalIntensityDuration) * 100 : 0])
    );
  
    return { 
      medianMood: weekMarkers.length > 0 ? weekMarkers.find(marker => moodToNumeric(marker.mood) === medianMood)?.mood : 'No data', 
      meanMood,
      medianRPE, 
      medianFeeling, 
      sessionCount, 
      totalDuration, 
      totalTrainingLoad, 
      activityDurations, 
      intensityZoneDurations, 
      intensityZonePercents, 
      meanRPE,
      meanFeeling
    };
  };
  

  const { 
    medianMood,
    meanMood, 
    medianRPE, 
    medianFeeling, 
    sessionCount, 
    totalDuration, 
    totalTrainingLoad,
    activityDurations,
    intensityZoneDurations,
    intensityZonePercents,
    meanRPE, meanFeeling
} = calculateStatistics(week);

  return (
    <Box mb={2}>
        <Typography variant="h6">Statistics for Week {weekNumber}</Typography>
        <Typography variant="body1">Median/mean mood: {medianMood}/{meanMood.toFixed(1)} of 4</Typography>
        <Typography variant="body1">Median/mean feeling: {medianFeeling}/{meanFeeling.toFixed(1)} of 4</Typography>
        <Typography variant="body1">Median/mean RPE: {medianRPE}/{meanRPE.toFixed(1)}</Typography>

        <Typography variant="body1">/</Typography>
        <Typography variant="body1">Sessions: {sessionCount}</Typography>
        <Typography variant="body1">Trainingload: {totalTrainingLoad}</Typography>
        <Typography variant="body1">Training duration: {(totalDuration/60).toFixed(1)} hours</Typography>
        <Typography variant="body1">/</Typography>
        <Typography variant="body1">Duration and distance in each activity:</Typography>
            {Object.entries(activityDurations).map(([activity, {duration, distance}]) => (
                <Typography key={activity}>{`${activity}: ${(duration/60).toFixed(1)} hours and ${distance.toFixed(1)} km`}</Typography>
                ))}
        <Typography variant="body1">/</Typography>
        <Typography variant="body1">Time and percent intensity zones:</Typography>
            {Object.entries(intensityZoneDurations).map(([zone, duration]) => (
                    <Typography>{`Zone ${zone}: ${(duration/60).toFixed(1)} hours/${intensityZonePercents[zone].toFixed(0)}%`}</Typography>
            ))}
        {/* Additional statistics can be added here */}
    </Box>
);
};

export default StatsWeeklyAdmindashComp;
