import React, {useState} from 'react';
import { Button, TextField, Box, Typography, Select, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { registerUser, authenicateUserForRegistration} from '../api';

//TODO: Implement stripe-payments

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
  });

  const handleChange = (e:any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const authResponse = await authenicateUserForRegistration(formData.email, formData.role);
      console.log(authResponse);

      if (authResponse.message === 'User can register') {
        const data = await registerUser(formData);
        
        console.log(data);
        navigate('/login');
      } else {
        // Handle the case where email authentication fails
        alert('Email authentication failed. Please reach out to your coach');
        console.error('Email authentication failed:', authResponse.message);
        // Optionally show an error message to the user
      }
    } catch (error) {
      alert('Registration failed. Please try again');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="background.default"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Registration
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
        <TextField
            label="Email"
            variant="outlined"
            fullWidth
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
        <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
        <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
        <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
        <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              value={formData.role}
              label="Role"
              name="role"
              onChange={handleChange}
            >
              <MenuItem value="coach">Coach</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="athlete">Athlete</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="secondary" /> : 'Register'}
        </Button>
      </form>
    </Box>
  );
};

export default RegistrationPage;
