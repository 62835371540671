import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography, Button } from '@mui/material';
import jwt_decode from 'jwt-decode';
import jwtDecode from 'jwt-decode';

import { getInvitations, acceptInvitation, declineInvitation, getSubStatusByUserId } from '../api'; 

interface DecodedToken {
  id: string;
  activeSubscription: boolean;
  exp: number;
  // Include any other properties you expect to be in the token
}

interface Invitation {
  _id: string;
  email: string;
}

const InvitationsPage = () => {
  const [invitations, setInvitations] = useState<Invitation[]>([])
  
useEffect(() => {
  const fetchInvitations = async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('No token found');
      return;
    }
    const decodedToken = jwt_decode(token) as DecodedToken;
    const userId = decodedToken.id;

    const result = await getInvitations(userId);
    setInvitations(result);
  };

  fetchInvitations();
}, []);

const navigate = useNavigate();

useEffect(() => {
  // Check if a valid token exists in localStorage
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken:DecodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds

    if (decodedToken.exp > currentTime) {

    } else {
      localStorage.removeItem('token');
      navigate('/login', { replace: true });
    }
    if (decodedToken.activeSubscription === false) {
      navigate('/subscription', { replace: true });
    } 
  }

  
}, []);

const getsubscriptionStatus = async () => {
  try {
    const token = localStorage.getItem('token');
    
    if (token === null) {
      console.error("Token not found in local storage");
      return; // End the function if there's no token
    }

    const decodedToken = jwt_decode(token) as DecodedToken;
    const userId = decodedToken.id;

    const subStatus = await getSubStatusByUserId(userId);
    console.log(subStatus);
    if(subStatus.subscriptionStatus === true) {
      // navigate('/subscription', { replace: true });
      console.log('active')
    } else {
      navigate('/subscription', { replace: true });
      console.log('not active')
    }
  } catch (error:any) {
    console.error(error.message);
  }
}

useEffect(() => {
  getsubscriptionStatus();
}, []);     

const handleAccept = async (inviterId: string) => {
  try {
    // Call the API
    await acceptInvitation(inviterId);
  
    // Remove accepted invitation from the list
    setInvitations(invitations.filter(invitation => invitation._id !== inviterId));
  } catch (error) {
    console.error(error);
    alert('Error accepting invitation. Please try again later.');
  }
};

const handleDecline = async (inviterId: string) => {
  try {
    await declineInvitation(inviterId);

    setInvitations(invitations.filter(invitation => invitation._id !== inviterId));
  } catch (error) {
    console.error(error);
    alert('Error accepting invitation. Please try again later.');
  }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="background.default"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Invitations
      </Typography>
      {invitations.map(invitation => (
        <Box key={invitation._id}>
          <Typography variant="body1">{invitation.email}</Typography>
          <Button variant="contained" color="primary" onClick={() => handleAccept(invitation._id)}>
            Accept
          </Button>
          <Button variant="contained" color="warning" onClick={() => handleDecline(invitation._id)}>
            Decline
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default InvitationsPage;
