import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, TooltipProps, ResponsiveContainer } from 'recharts';
import { getWeek } from 'date-fns';
import { Container, Typography, Select, MenuItem, Box, useTheme, useMediaQuery } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface DataPoint {
  // Define your data point interface here, based on your data structure
}

interface DataType {
  name: string;
  uv: number;
  mood: string;
}

const StatsPage: React.FC = () => {
  const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
  const [selectedParameter, setSelectedParameter] = useState<string | null>(null);
  const [selectedActivity, setSelectedActivity] = useState<string | null>(null);

  const activityList: string[] = [];

  const handleActivityChange = (event: SelectChangeEvent) => {
    setSelectedActivity(event.target.value as string || null);
  };

  const data = [
    {name: 'Day 1', uv: 4000, mood: '😊'},
    {name: 'Day 2', uv: 3000, mood: '😐'},
    {name: 'Day 3', uv: 2000, mood: '😀'},
    {name: 'Day 4', uv: 2780, mood: '😟'},
    {name: 'Day 5', uv: 1890, mood: '😁'},
  ];
  
  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`Day: ${label}`}</p>
          <p>{`sRPE: ${payload[0].value}`}</p>
          <p>{`Mood: ${payload[0].payload.mood}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const CustomDot: React.FC<any> = ({cx, cy, payload}) => {
    return (
      <text x={cx} y={cy} dy={-10} textAnchor="middle" fill="#8884d8" fontSize={24}>
        {payload.mood}
      </text>
    );
  };

  return (
    <>
    <ResponsiveContainer width="100%" height={400}>
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Line type="monotone" dataKey="uv" stroke="#8884d8" dot={<CustomDot />} />
    </LineChart>
  </ResponsiveContainer>
  
  <Container maxWidth={false}>
        <Typography variant="h5" component="div" gutterBottom align="center">
          EWMA ACWR Chart with poor mood bars
        </Typography>
        <Typography variant="h5" component="div" gutterBottom align="center">
          barchart weekly loads
        </Typography>
        <Typography variant="h5" component="div" gutterBottom align="center">
          barchart intensityzonedistribution if used
        </Typography>
        <Typography variant="h5" component="div" gutterBottom align="center">
          Yearly Comparison
        </Typography>
        <Typography>Select activity:</Typography>
        <Select
          fullWidth
          value={selectedActivity || ''}
          onChange={handleActivityChange}
        >
          <MenuItem value="">All activities</MenuItem>
          {activityList.map((activity) => (
            <MenuItem key={activity} value={activity}>
              {activity}
            </MenuItem>
          ))}
        </Select>
        <Box width="100%" overflow="hidden">
          <ResponsiveContainer width="100%" height={300}>
            <Typography> Linechart </Typography>
            {/* <LineChart
              data={prepareLineChartData(filteredSessions)}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="weekId" />
              <YAxis yAxisId="left" hide />
              <YAxis yAxisId="middle" orientation="right" hide />
              <YAxis yAxisId="right" orientation="right" hide />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="trainingFrequency" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line yAxisId="middle" type="monotone" dataKey="distanceCovered" stroke="#82ca9d" />
              <Line yAxisId="right" type="monotone" dataKey="totalDuration" stroke="#d88884" />
            </LineChart> */}
          </ResponsiveContainer>
        </Box>
        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mt: 3 }}>
          Activity Distribution
        </Typography>
        <Box display="flex" justifyContent="center">
          {/* <PieChart
            width={isMobile ? window.innerWidth - 20 : 400}
            height={isMobile ? window.innerWidth - 20 : 400}
          >
            <Pie
              data={prepareActivityDistributionData(trainingSessions)}
              cx={isMobile ? (window.innerWidth - 20) / 2 : 200}
              cy={isMobile ? (window.innerWidth - 20) / 2 : 200}
              outerRadius={isMobile ? (window.innerWidth - 20) / 4 : 100}
              fill="#8884d8"
              dataKey="value"
              nameKey="name"
              label
            />
            <Tooltip />
          </PieChart> */}
        </Box>
        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mt: 3 }}>
          Completion Rate
        </Typography>
        <Typography variant="h5" component="div" align="center" sx={{ mb: 3 }}>
          {/* {calculateCompletionRate(trainingSessions).toFixed(2)}% */}
        </Typography>
        <Typography variant="body2" align="center" color="text.secondary" sx={{ mt: 5 }}>
          Copyright AP-Performance Solutions AS 2023
        </Typography>
      </Container>
      </>
  );
}

export default StatsPage;